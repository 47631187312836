import { Box, IconButton } from '@mui/material';
import { MoreVertical } from 'common/components/Icons';
import AccommodationCardMenu from 'kurt/components/PatientFlowKanban/AccommodationCardMenu';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import getColumnActions from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';
import { useRef, useState } from 'react';
import MovementsActionForm from '.';
import { MovementRow } from '../../MovementRow';

const MovementActionMenu = ({
  flow,
  type,
  status,
  movement,
  unitId,
}: {
  flow: 'admission' | 'discharge';
  type: 'mutation' | 'default';
  status: 'planned' | 'validated' | 'completed' | 'requested' | 'blocked';
  movement: MovementRow;
  unitId?: string;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [action, setAction] = useState<AccommodationAction>();

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const actions = getColumnActions({ flow, type, status });

  const handleClose = () => {
    setActionOpen(false);
    setAction(undefined);
  };

  const handleAction = async (action: AccommodationAction) => {
    setAction(action);
    setActionOpen(true);
    toggleMenu();
  };

  return (
    <Box display="flex" alignItems="center" onClick={(event) => event.stopPropagation()}>
      <IconButton sx={{ margin: -1 }} ref={menuButtonRef} onClick={toggleMenu}>
        <MoreVertical />
      </IconButton>
      {action && (
        <MovementsActionForm
          action={action}
          open={actionOpen}
          onCancel={handleClose}
          {...movement}
          unitId={unitId}
          patient={{ name: movement.patientName, gender: movement.patientGender, birthdate: movement.patientBirthdate }}
        />
      )}
      <AccommodationCardMenu
        open={menuOpen}
        anchorEl={menuButtonRef.current}
        onClose={toggleMenu}
        actions={actions}
        onActionClick={handleAction}
        visitId={movement.visitId}
      />
    </Box>
  );
};

export default MovementActionMenu;

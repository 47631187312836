import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { useLocations } from 'core/locations';
import UnitSelector, { UnitAutocompleteOption } from 'kurt/components/UnitAutocomplete';
import { FC, useState } from 'react';
import { AccommodationAction } from '..';
import { ActionProps } from '../ActionForm';

type UpdateUnitProps = ActionProps & {
  action: AccommodationAction;
};

const UpdateUnit: FC<UpdateUnitProps> = ({ action, loading = false, onCancel, onSubmit }) => {
  const { units } = useLocations();
  const [selectedUnit, setSelectedUnit] = useState<UnitAutocompleteOption | null>(null);
  const [error, setError] = useState(false);

  const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }));

  const handleSubmit = async () => {
    if (!selectedUnit) {
      setError(true);
    } else {
      onSubmit({ unitId: selectedUnit.value });
    }
  };

  const handleChange = (unit: UnitAutocompleteOption | null) => {
    setError(false);
    setSelectedUnit(unit);
  };

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h4">{i18n.visitActions[action].form.question}</Typography>
        <UnitSelector options={unitOptions} selected={selectedUnit} onChange={(_, value) => handleChange(value)} />

        {error && (
          <Typography variant="body2" color="error">
            {i18n.addAccommodationModal.errors.unit}
          </Typography>
        )}
      </Stack>

      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={handleSubmit} loading={loading} disabled={loading || !selectedUnit}>
          {i18n.visitActions.UPDATE_UNIT.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </>
  );
};

export default UpdateUnit;

import { Card, IconButton, Stack, TextFieldProps, Typography } from '@mui/material';
import CrossIcon from 'common/components/Icons/Cross';
import React from 'react';
import UnitAutocomplete from './UnitAutocomplete';

type Option = { value: string; label: string };

type UnitSelectorProps = {
  options: Option[];
  selected: Option | null;
  onChange: (_: any, value: Option | null) => void;
  error?: { message: string };
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  disabled?: boolean;
};

const UnitSelector: React.FC<UnitSelectorProps> = ({
  options,
  selected,
  onChange,
  error,
  variant = 'outlined',
  size = 'medium',
  disabled = false,
}) => {
  return (
    <Stack spacing={2}>
      <UnitAutocomplete
        selected={selected}
        onChange={onChange}
        options={options}
        error={error}
        variant={variant}
        size={size}
        disabled={disabled}
      />
      {selected && (
        <Card elevation={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding={`${size === 'small' ? 10 : 14}px 12px`}
          >
            <Typography variant={size === 'small' ? 'body2' : 'body1'} fontWeight={600}>
              {selected.label}
            </Typography>
            <IconButton onClick={() => onChange(null, null)} size={size}>
              {<CrossIcon />}
            </IconButton>
          </Stack>
        </Card>
      )}
    </Stack>
  );
};

export default UnitSelector;

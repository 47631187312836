import { Chip, Stack, Typography } from '@mui/material';
import { theme } from 'common/theme';
import { BadgeIcon } from './BadgeIcon';

export type StatisticsColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'error'
  | 'primaryAlt';
export type ItemTitleProps = {
  label: string;
  Icon?: React.FC;
  variant: 'light' | 'chip' | 'default';
  color: StatisticsColor;
};

export const ItemTitle = ({ color, label, variant, Icon }: ItemTitleProps) => {
  switch (variant) {
    case 'chip':
      return <Chip label={label} color={color} variant="light" size="small" sx={{ width: 100, cursor: 'inherit' }} />;
    case 'light':
      return (
        <Typography variant="body2" color={theme.palette[color].main} noWrap>
          {label}
        </Typography>
      );
    default:
      return (
        <Stack direction="row" alignItems="center" justifyContent="center" width="100%" spacing={1}>
          {Icon && <BadgeIcon color={theme.palette[color].main} Icon={Icon} />}
          <Typography variant="body2" color={theme.palette[color].dark}>
            {label}
          </Typography>
        </Stack>
      );
  }
};

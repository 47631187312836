import { Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { AddressMutationRequest } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type AddressMutationRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  accommodationRequestId: string;
};

export const AddressMutationRequestDialogForm = ({
  accommodationRequestId,
  onClose,
  onBack,
}: {
  accommodationRequestId: string;
  onClose: () => void;
  onBack?: () => void;
}) => {
  const handleSubmit = async (_: any, { performer }: { performer: string }) => {
    await Cloud.createPerformerRequest({ requestId: accommodationRequestId, performerId: performer });
    onClose();
  };
  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.visitActions.ADDRESS_MUTATION_REQUEST.action.label} />
      <DialogContent>
        <AddressMutationRequest onCancel={onBack || onClose} onSubmit={handleSubmit} />
      </DialogContent>
    </>
  );
};

export const AddressMutationRequestDialog = ({
  open,
  onClose,
  accommodationRequestId,
}: AddressMutationRequestDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <AddressMutationRequestDialogForm accommodationRequestId={accommodationRequestId} onClose={onClose} />
    </Dialog>
  );
};

import Api, { ACCESSIBLE_ROLES, Cloud, User, UserRole } from '@ambuliz/sabri-core';
import AuthenticatedUser from 'core/authentication/types/User';
import { useMemo } from 'react';
import { Pagination } from '../hooks/usePagination';
import { UserCreateForm, UserUpdateForm } from './UserManagement.model';

export const useBuildUserQuery = (
  pagination: Pagination,
  connectedUser: AuthenticatedUser,
  name: string,
  roles: UserRole[]
) => {
  const stringifiedRoles = JSON.stringify(roles);
  const stringifiedPagination = JSON.stringify(pagination);

  const query = useMemo(() => {
    const roles = JSON.parse(stringifiedRoles);
    const pagination = JSON.parse(stringifiedPagination);

    const usersByUsernameQuery = new Api.Query(User).contains('username', name);
    const usersByFirstNameQuery = new Api.Query(User).contains('porter.firstName', name);
    const usersByLastameQuery = new Api.Query(User).contains('porter.lastName', name);

    const query = name
      ? Parse.Query.or(usersByUsernameQuery, usersByFirstNameQuery, usersByLastameQuery)
      : new Parse.Query(User);

    query.limit(pagination.pageSize).skip(pagination.currentPageIndex * pagination.pageSize);

    const emptyRolesFilter = roles.length === 0;
    if (emptyRolesFilter) {
      query.containedIn('role', ACCESSIBLE_ROLES[connectedUser.role]);
    } else {
      query.containedIn('role', roles);
    }

    return query;
  }, [stringifiedRoles, stringifiedPagination, connectedUser.role, name]);

  return query;
};

export const createUser = async (
  user: UserCreateForm & {
    unit?: string;
    lastName?: string;
    firstName?: string;
  }
) => {
  if (user.healthCenterId) {
    try {
      const newUserResult = await Cloud.createUser({ ...user, unitId: user.unit });
      return newUserResult.user;
    } catch (err) {
      throw new Error(err.message);
    }
  }
};

export const updateUser = async (
  user: UserUpdateForm & {
    unit?: string;
    lastName?: string;
    firstName?: string;
  }
) => {
  if (user.objectId) {
    try {
      const updatedUserResult = await Cloud.updateUser({
        unitId: user.unit,
        lastName: user.lastName,
        firstName: user.firstName,
        id: user.objectId,
        healthCenterId: user.healthCenterId,
        notificationConfigurations: user.notificationConfigurations,
        navigationACL: user.navigationACL,
        units: user.units,
        isReadOnly: user.isReadOnly,
        whitelistedIPs: user.whitelistedIPs,
      });
      return updatedUserResult.user;
    } catch (err) {
      throw new Error(err.message);
    }
  }
};

import { accommodationSpecificities } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { ToggleGroup } from 'common/components';
import { DateTimeInput, FilterSelect } from 'common/components/Form';
import BedIcon from 'common/components/Icons/Bed';
import CalendarIcon from 'common/components/Icons/Calendar';
import CommentIcon from 'common/components/Icons/Comment';
import EditIcon from 'common/components/Icons/Edit';
import EntryDashedIcon from 'common/components/Icons/EntryDashed';
import HeartBeatIcon from 'common/components/Icons/HeartBeat';
import StandIcon from 'common/components/Icons/Stand';
import TicketIcon from 'common/components/Icons/Ticket';

import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { i18n } from 'common/locale';
import { useLocations } from 'core/locations';
import { getHours, getMinutes, isValid } from 'date-fns';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import DialogFormRow from '../DialogFormRow';
import SpecificityAutocomplete from '../SpecificityAutocomplete';
import DischargeStatusSelect from '../StepStatus/DischargeStatusSelect';
import { AdmissionToCreate, RequestType } from './CreateAdmissionDialog';

type CreateAdmissionFormProps = {
  onSubmit: (admission: AdmissionToCreate, isRequest?: boolean) => void;
  onCancel?: () => void;
  initialAdmission?: Partial<AdmissionToCreate>;
  loading?: boolean;
  selectUnitStep?: boolean;
  error?: ReactElement;
  availableUnits?: string[];
};

const CreateAdmissionForm: React.FC<CreateAdmissionFormProps> = ({
  onSubmit,
  onCancel,
  initialAdmission,
  loading,
  selectUnitStep,
  error,
  availableUnits,
}) => {
  const [type, setType] = useState<RequestType>('DIRECT_ADMISSION');
  const [patient, setPatient] = useState<PatientSearchResult | null>(null);
  const [errors, setErrors] = useState(initialErrors);

  const [admission, setAdmission] = useState<Partial<AdmissionToCreate>>({ ...initialAdmission, type });
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  const { units: allUnits } = useLocations();

  const units = useMemo(() => {
    if (availableUnits) {
      return allUnits.filter(({ id }) => availableUnits.includes(id));
    } else {
      return allUnits;
    }
  }, [availableUnits, allUnits]);

  const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }));
  const accommodationSpecificityOptions = [
    ...accommodationSpecificities.filter(
      // ISOLATED is now deprecated and no more available
      (spec) => !['WAITING_FOR_DOWNSTREAM_BED', 'ISOLATED'].includes(spec)
    ),
  ];

  const handleChange =
    <T extends keyof AdmissionToCreate>(field: T) =>
    (value?: AdmissionToCreate[T]) => {
      setAdmission((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [field]: false }));
    };

  const handleTypeChange = (type: RequestType) => {
    setType(type);
    setErrors((prev) => {
      const newState = { ...prev };
      newState.startAt = false;
      newState.endAt = false;
      newState.directAdmissionOrigin = false;

      return newState;
    });
    handleChange('type')(type);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validation = formValidation[type];
    const validationErrors = validation && validation(admission);
    if (validationErrors && Object.values(validationErrors).includes(true)) {
      setErrors({ ...errors, ...validationErrors });
    } else {
      setHasBeenCalled(true);
      if (hasBeenCalled) {
        return;
      }
      const commonParams = {
        unitId: admission.unitId,
        visitId: admission.visitId,
        specificities: admission.specificities,
        comment: admission.comment,
      };
      if (type === 'DIRECT_ADMISSION') {
        onSubmit(
          {
            ...commonParams,
            type: 'DIRECT_ADMISSION',
            directAdmissionOrigin: admission.directAdmissionOrigin,
            reason: admission.reason,
          } as AdmissionToCreate,
          true
        );
      } else {
        onSubmit({
          ...commonParams,
          type: 'SCHEDULED_ADMISSION',
          startAt: admission.startAt,
          endAt: admission.endAt,
          isEndEstimated: admission.isEndEstimated,
          unitId: admission.unitId,
        } as AdmissionToCreate);
      }
    }
  };

  const handleChangeText =
    (field: 'reason' | 'comment') => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const hasLineBreak = event.target.value.match(/$/gm);

      if (hasLineBreak && hasLineBreak.length > 3) {
        return;
      }

      handleChange(field)(event.target.value);
    };

  useEffect(() => {
    if (!!error && !loading) {
      setHasBeenCalled(false);
    }
  }, [error, loading]);

  return (
    <>
      <DialogContent>
        <form onSubmit={handleSubmit} id="add-admission-form">
          <Stack divider={<Divider />}>
            <DialogFormRow label={i18n.createAdmission.steps.requestType.label} Icon={TicketIcon}>
              <ToggleGroup
                variant="filled"
                size="small"
                value={type}
                onChange={(_, value) => handleTypeChange(value)}
                fullWidth
              >
                <ToggleButton value="DIRECT_ADMISSION" sx={{ flex: 1 }}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <EntryDashedIcon color="tertiary" />
                    {i18n.addAccommodationModal.selectToggle.directAdmission.label}
                  </Stack>
                </ToggleButton>
                <ToggleButton value="SCHEDULED_ADMISSION" sx={{ flex: 1 }}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <CalendarIcon color="success" />
                    {i18n.addAccommodationModal.selectToggle.scheduledAdmission.label}
                  </Stack>
                </ToggleButton>
              </ToggleGroup>
            </DialogFormRow>
            <DialogFormRow label={i18n.createAdmission.steps.patientChoice.label} Icon={StandIcon}>
              <PatientAutocomplete
                onChange={(patient) => {
                  setPatient(patient);
                  handleChange('visitId')(patient?.id);
                }}
                value={patient}
                size="small"
                variant="filled"
                error={errors.visitId ? { message: i18n.addAccommodationModal.errors.patient } : undefined}
              />
            </DialogFormRow>
            {type === 'DIRECT_ADMISSION' && (
              <DialogFormRow label={i18n.createAdmission.steps.directAdmissionOrigin.label} Icon={EntryDashedIcon}>
                <Stack spacing={2}>
                  <TextField
                    value={admission.directAdmissionOrigin}
                    onChange={(event) => handleChange('directAdmissionOrigin')(event.target.value)}
                    placeholder={i18n.createAdmission.steps.directAdmissionOrigin.placeholder}
                    error={errors.directAdmissionOrigin}
                    fullWidth
                    variant="filled"
                    size="small"
                  />
                  {errors.directAdmissionOrigin && (
                    <Typography variant="body2" color="error">
                      {i18n.addAccommodationModal.errors.directAdmissionOrigin}
                    </Typography>
                  )}
                </Stack>
              </DialogFormRow>
            )}
            {type === 'DIRECT_ADMISSION' && (
              <DialogFormRow label={i18n.createAdmission.steps.reason.label} Icon={HeartBeatIcon}>
                <TextField
                  multiline
                  fullWidth
                  minRows={1}
                  maxRows={3}
                  variant="filled"
                  size="small"
                  placeholder={i18n.createAdmission.steps.reason.placeholder}
                  value={admission.reason}
                  onChange={handleChangeText('reason')}
                />
              </DialogFormRow>
            )}

            <DialogFormRow label={i18n.createAdmission.steps.specificities.label} Icon={EditIcon}>
              <SpecificityAutocomplete
                onChange={handleChange('specificities')}
                value={admission.specificities || []}
                options={accommodationSpecificityOptions}
              />
            </DialogFormRow>
            {type !== 'DIRECT_ADMISSION' && (
              <DialogFormRow label={i18n.createAdmission.steps.dates.startAt} Icon={CalendarIcon}>
                <Stack spacing={1}>
                  <DateTimeInput
                    variant="filled"
                    size="small"
                    value={admission.startAt}
                    onChange={handleChange('startAt')}
                    maxDate={admission.endAt || undefined}
                    defaultTime={
                      !!admission.endAt
                        ? {
                            hour: getHours(admission.endAt),
                            minute: getMinutes(admission.endAt),
                          }
                        : undefined
                    }
                    error={errors.startAt}
                  />
                  {errors.startAt && (
                    <Typography fontSize={12} color="error">
                      {i18n.addAccommodationModal.errors.start}
                    </Typography>
                  )}
                </Stack>
              </DialogFormRow>
            )}
            {type !== 'DIRECT_ADMISSION' && (
              <DialogFormRow label={i18n.createAdmission.steps.dates.endAt} Icon={CalendarIcon}>
                <Stack gap={2} direction="row" justifyContent="space-between">
                  <DateTimeInput
                    variant="filled"
                    size="small"
                    value={admission.endAt}
                    onChange={handleChange('endAt')}
                    minDate={admission.startAt || undefined}
                    defaultTime={
                      !!admission.startAt
                        ? {
                            hour: getHours(admission.startAt),
                            minute: getMinutes(admission.startAt),
                          }
                        : undefined
                    }
                    error={errors.endAt}
                  />
                  <DischargeStatusSelect
                    value={admission.isEndEstimated ? 'ESTIMATED' : 'VALIDATED'}
                    onChange={(value) => handleChange('isEndEstimated')(value === 'ESTIMATED')}
                  />
                </Stack>
                {errors.endAt && (
                  <Typography fontSize={12} color="error">
                    {i18n.addAccommodationModal.errors.end}
                  </Typography>
                )}
              </DialogFormRow>
            )}

            <DialogFormRow label={i18n.createAdmission.steps.comment.label} Icon={CommentIcon}>
              <TextField
                multiline
                fullWidth
                minRows={1}
                maxRows={3}
                variant="filled"
                size="small"
                placeholder={i18n.createAdmission.steps.comment.placeHolder}
                value={admission.comment}
                onChange={handleChangeText('comment')}
              />
            </DialogFormRow>
            {selectUnitStep && (
              <DialogFormRow label={i18n.createAdmission.steps.unitChoice.label} Icon={BedIcon}>
                <Stack direction="row" spacing={2}>
                  <Stack spacing={1}>
                    <div style={{ alignSelf: 'flex-start' }}>
                      <FilterSelect
                        searchable
                        label={i18n.unit}
                        value={admission.unitId}
                        onChange={(unitId) => handleChange('unitId')(unitId)}
                        variant="filled"
                        size="small"
                        error={errors.unitId}
                      >
                        {unitOptions.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </FilterSelect>
                    </div>

                    {errors.unitId && (
                      <Typography variant="body2" color="error">
                        {i18n.addAccommodationModal.errors.unit}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </DialogFormRow>
            )}
          </Stack>
        </form>
        {error && <Stack paddingTop={6}>{error}</Stack>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {i18n.cancel}
        </Button>
        <LoadingButton disabled={loading} loading={loading} type="submit" form="add-admission-form">
          {i18n.addAccommodationModal.programmAdmission}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateAdmissionForm;

type Errors = {
  visitId: boolean;
  unitId: boolean;
  startAt: boolean;
  endAt: boolean;
  directAdmissionOrigin: boolean;
  type: boolean;
};

const initialErrors: Errors = {
  visitId: false,
  unitId: false,
  startAt: false,
  endAt: false,
  directAdmissionOrigin: false,
  type: false,
};

const formValidation: Record<
  RequestType,
  (admission: Partial<AdmissionToCreate>) => { [key in keyof AdmissionToCreate]?: boolean }
> = {
  SCHEDULED_ADMISSION: (admission) => ({
    visitId: !admission.visitId,
    startAt: !admission.startAt || !isValid(admission.startAt),
    endAt: !!admission.endAt && !!admission.startAt && admission.startAt > admission.endAt,
    unitId: !admission.unitId,
  }),

  DIRECT_ADMISSION: (admission) => ({
    visitId: !admission.visitId,
    directAdmissionOrigin: !admission.directAdmissionOrigin,
  }),
};

import Api, { Area } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

export const useOrphanAreas = () => {
  const { results, isLoading } = useParseQuery(
    new Api.Query(Area).notEqualTo('externalTypeCode', 'hospital').doesNotExist('parent')
  );

  return {
    orphanAreas: results,
    isLoading,
  };
};

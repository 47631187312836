import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Avatar } from '@mui/material';
import { Accommodated, Covid, Hourglass, Isolated, PrivateRoom } from 'common/components/Icons';
import { color } from 'common/theme';
import { ReactElement } from 'react';

type AccommodationSpecificityBadgeProps = {
  specificity: AccommodationSpecificity;
};

const AccommodationSpecificityBadge: React.FC<AccommodationSpecificityBadgeProps> = ({ specificity }) => {
  return (
    <Avatar
      sx={{
        bgcolor: badges[specificity].color,
        width: 20,
        height: 20,
      }}
    >
      {badges[specificity].icon}
    </Avatar>
  );
};

const badges: Record<AccommodationSpecificity, { icon: ReactElement; color: string }> = {
  COVID: { icon: <Covid sx={{ fontSize: 12 }} />, color: color.red[60] },
  ACCOMMODATED: { icon: <Accommodated sx={{ fontSize: 12 }} />, color: color.yellow[70] },
  ISOLATED: { icon: <Isolated sx={{ fontSize: 12 }} />, color: color.orange[50] },
  ISOLATED_AIR: { icon: <Isolated sx={{ fontSize: 12 }} />, color: color.orange[50] },
  ISOLATED_DROPLET: { icon: <Isolated sx={{ fontSize: 12 }} />, color: color.orange[50] },
  ISOLATED_CONTACT: { icon: <Isolated sx={{ fontSize: 12 }} />, color: color.orange[50] },
  ISOLATED_PROTECTOR: { icon: <Isolated sx={{ fontSize: 12 }} />, color: color.orange[50] },
  PRIVATE_ROOM: { icon: <PrivateRoom sx={{ fontSize: 12 }} />, color: color.blue[30] },
  WAITING_FOR_DOWNSTREAM_BED: { icon: <Hourglass sx={{ fontSize: 12 }} />, color: color.blue[50] },
};

export default AccommodationSpecificityBadge;

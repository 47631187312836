import { AccommodationSpecificity, Cloud, RequestType } from '@ambuliz/sabri-core';
import { UpdateAccommodationRequestParams } from "@ambuliz/sabri-core/dist/cloud";
import { useState } from 'react';

export type AccommodationRequestToCreate = {
  locationId?: string;
  expectedAt?: Date;
  visitId: string;
  requestType: RequestType;
  specificities?: AccommodationSpecificity[];
  performerUnitIds?: string[];
  comment?: string;
  reason?: string;
  directAdmissionOrigin?: string;
};

const useAccommodationRequest = (id?: string) => {
  const [loading, setLoading] = useState(false);

  const create = async (accommodationRequest: AccommodationRequestToCreate) => {
    setLoading(true);
    try {
      await Cloud.createAccommodationRequest(accommodationRequest);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const update = async (accommodationRequest: UpdateAccommodationRequestParams) => {
    setLoading(true);
    try {
      await Cloud.updateAccommodationRequest(accommodationRequest);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const cancel = async () => {
    setLoading(true);
    if (id) {
      try {
        await Cloud.cancelAccommodationRequest({ requestId: id });
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  return { create, update, cancel, loading };
};

export default useAccommodationRequest;

import { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import { Box, IconButton } from '@mui/material';
import { MoreVertical } from 'common/components/Icons';
import { formatName } from 'common/utils';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { useRef, useState } from 'react';
import MovementsActionForm from '.';
import AdmissionAccommodationCardMenu from './AdmissionAccommodationCardMenu';

const MovementActionMenu = ({ movementId, unitId }: { movementId: string; unitId?: string }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [action, setAction] = useState<AccommodationAction>();
  const [accommodation, setAccommodation] = useState<Accommodation>();
  const [previousAccommodation, setPreviousAccommodation] = useState<Accommodation>();
  const [mutation, setMutation] = useState<AccommodationRequest>();

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const openMenu = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setActionOpen(false);
    setAction(undefined);
  };

  const handleAction = async (action: AccommodationAction) => {
    setAction(action);
    setActionOpen(true);
    setMenuOpen(false);
  };

  const onMenuLoaded = ({
    accommodation,
    mutation,
    previousAccommodation,
  }: {
    accommodation: Accommodation;
    mutation?: AccommodationRequest;
    previousAccommodation?: Accommodation;
  }) => {
    setAccommodation(accommodation);
    setPreviousAccommodation(previousAccommodation);
    setMutation(mutation);
  };

  return (
    <Box display="flex" alignItems="center" onClick={(event) => event.stopPropagation()}>
      <IconButton sx={{ margin: -1 }} ref={menuButtonRef} onClick={openMenu}>
        <MoreVertical />
      </IconButton>
      {action && (
        <MovementsActionForm
          action={action}
          open={actionOpen}
          onCancel={handleClose}
          id={movementId}
          unitId={unitId}
          performerRequests={mutation?.performerRequests}
          specificities={accommodation?.specificities}
          dischargeDate={accommodation?.endAt}
          admissionDate={accommodation?.startAt}
          bedId={accommodation?.bed?.id}
          previousAccommodationId={previousAccommodation?.id}
          patient={{
            name: formatName(
              accommodation?.visit?.firstName,
              accommodation?.visit?.lastName,
              accommodation?.visit?.legalName,
              accommodation?.visit?.legalFirstName
            ),
            gender: accommodation?.visit?.gender,
            birthdate: accommodation?.visit?.birthdate,
          }}
        />
      )}
      {menuOpen && (
        <AdmissionAccommodationCardMenu
          open={menuOpen}
          anchorEl={menuButtonRef.current}
          onClose={() => setMenuOpen(false)}
          onActionClick={handleAction}
          onLoaded={onMenuLoaded}
          movementId={movementId}
        />
      )}
    </Box>
  );
};

export default MovementActionMenu;

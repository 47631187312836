import Api, { ThesaurusItem } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';

type useThesaurusOptions = {
  page: number;
  rowsPerPage: number;
};

export const useThesaurusPaginated = (options?: useThesaurusOptions) => {
  const page = options?.page || 0;
  const rowsPerPage = options?.rowsPerPage || 10;

  const query = new Api.Query(ThesaurusItem)
    .include('mainRedirection', 'redirection', 'alternateRedirection')
    .limit(rowsPerPage)
    .skip(page * rowsPerPage);

  const { results, isLoading } = useParseQuery(query, {
    enableLiveQuery: false,
  });
  const { count } = useParseQueryCount(query);

  return { thesaurus: results, count, isLoading };
};

import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import { AccommodationResultList } from './Components/AccommodationResultList';
import { AccommodationSearchFilter, AccommodationSearchFilters } from './Components/AccommodationSearchFilters';
import { useAccommodationSearch } from './hooks/useAccommodationSearch';

export const Overview = (props: any) => {
  const [filters, setFilters] = useState<AccommodationSearchFilter>({
    date: new Date(),
    page: 0,
    rowsPerPage: 30,
  });

  const { accommodations, accommodationsCount, isLoading } = useAccommodationSearch(filters);

  return (
    <>
      <PageHeader title="Synthèse"></PageHeader>
      <PageSection>
        <AccommodationSearchFilters filters={filters} onFiltersChange={setFilters} />
      </PageSection>
      <PageSection>
        <AccommodationResultList
          accommodations={accommodations}
          count={accommodationsCount}
          onChange={(page, rowsPerPage) => setFilters({ ...filters, page, rowsPerPage })}
          isLoading={isLoading}
        />
      </PageSection>
    </>
  );
};

import { AccommodationSpecificity, AccommodationStatus, RequestStatus } from '@ambuliz/sabri-core';
import AddAccommodationRequestForm from 'kurt/components/SendAccommodationRequest/SendAccommodationRequestForm';
import { Visit } from 'kurt/pages/Movements/useVisits';
import {
  AcceptMutationRequest,
  CancelAccommodation,
  CancelBed,
  ConfirmAdmission,
  NewMutationRequest,
  RejectMutationRequest,
  UpdateBed,
  UpdateDate,
  UpdateDates,
  UpdateMutationDate,
  UpdateSpecificities,
  UpdateUnit,
} from './actionForms';
import { AccommodationAction } from './columnActions';

export type ActionProps = {
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (
    accommodation?: {
      startAt?: Date | null;
      endAt?: Date | null;
      bedId?: string | null;
      unitId?: string | null;
      specificities?: AccommodationSpecificity[];
      isEndEstimated?: boolean;
      status?: AccommodationStatus;
    },
    request?: {
      startAt?: Date | null;
      reason?: string;
      status?: RequestStatus;
      performer?: string;
    }
  ) => void;
};

type ActionFormProps = {
  action?: AccommodationAction;
  visit: Visit;
};

const ActionForm: React.FC<ActionProps & ActionFormProps> = ({ action, onCancel, onSubmit, visit, loading }) => {
  switch (action) {
    case 'SET_BED':
    case 'UPDATE_BED':
    case 'SET_MUTATION_BED':
      return (
        <UpdateBed
          onCancel={onCancel}
          initialUnitId={visit.unit.id}
          initialBedId={visit.bedId}
          action={action}
          onSubmit={onSubmit}
          loading={loading}
          start={visit.admissionDate}
          end={visit.dischargeDate}
          patient={visit.patient}
          specificities={visit.specificities}
        />
      );
    case 'UPDATE_UNIT':
      return <UpdateUnit onCancel={onCancel} action={action} onSubmit={onSubmit} loading={loading} />;
    case 'UPDATE_SPECIFICITIES':
      return (
        <UpdateSpecificities
          initialSpecificities={visit.specificities}
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={loading}
        />
      );
    case 'UPDATE_DATES':
      return <UpdateDates {...visit} onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'CANCEL_ACCOMMODATION':
      return <CancelAccommodation action={action} onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'CANCEL_ADMISSION':
    case 'CANCEL_MUTATION_REQUEST':
    case 'CANCEL_DISCHARGE':
      return <CancelAccommodation onCancel={onCancel} onSubmit={onSubmit} action={action} loading={loading} />;
    case 'CONFIRM_ADMISSION':
      return <ConfirmAdmission onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'DISCHARGE_PATIENT':
    case 'VALIDATE_DISCHARGE_DATE':
    case 'UPDATE_PLANNED_DISCHARGE_DATE':
    case 'UPDATE_VALIDATED_DISCHARGE_DATE':
    case 'INVALIDATE_DISCHARGE_DATE':
      return (
        <UpdateDate
          action={action}
          onCancel={onCancel}
          onSubmit={onSubmit}
          admissionDate={visit.admissionDate}
          dischargeDate={action === 'DISCHARGE_PATIENT' ? new Date() : visit.dischargeDate}
          loading={loading}
        />
      );
    case 'CANCEL_BED':
    case 'CANCEL_MUTATION_BED':
      return <CancelBed onCancel={onCancel} onSubmit={onSubmit} action={action} loading={loading} />;
    case 'ACCEPT_INTERNAL_MUTATION_REQUEST':
    case 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
      return <AcceptMutationRequest onCancel={onCancel} onSubmit={onSubmit} loading={loading} locale={action} />;
    case 'UPDATE_MUTATION_DATE':
      return <UpdateMutationDate onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'REJECT_INTERNAL_MUTATION_REQUEST':
    case 'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
      return <RejectMutationRequest onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'NEW_INTERNAL_MUTATION_REQUEST':
      return <NewMutationRequest onCancel={onCancel} onSubmit={onSubmit} loading={loading} />;
    case 'REQUEST_MUTATION':
      return <AddAccommodationRequestForm unitId={visit.unit.id} onSubmit={() => {}} />;
    default:
      return null;
  }
};

export default ActionForm;

import { ThesaurusItem } from '@ambuliz/sabri-core';
import { TableCell, TableRow } from '@mui/material';

type ThesaurusTableRowProps = {
  thesaurusItem: ThesaurusItem;
};

const convertMinutesToDays = (minutes: number) => {
  return minutes / (60 * 24);
};

export const ThesaurusTableRow = ({ thesaurusItem: { reason, expectedDurationInMinutes } }: ThesaurusTableRowProps) => {
  return (
    <TableRow>
      <TableCell>{reason}</TableCell>
      <TableCell>{expectedDurationInMinutes ? convertMinutesToDays(expectedDurationInMinutes) : '-'}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

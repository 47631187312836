import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Dialog, Link, Stack, Typography } from '@mui/material';
import { Alert, DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useSupport } from 'core/support/useSupport';
import { useAccommodation, useAccommodationRequest } from 'kurt/hooks';
import { useState } from 'react';
import CreateAdmissionForm from './CreateAdmissionForm';

type CreateAdmissionDialogContentProps = {
  onClose: () => void;
  onSuccess: () => void;
  unitId?: string;
  availableUnits?: string[];
};

type CreateAdmissionDialogProps = CreateAdmissionDialogContentProps & {
  open: boolean;
};

const CreateAdmissionDialogContent = ({
  onClose,
  onSuccess,
  unitId,
  availableUnits,
}: CreateAdmissionDialogContentProps) => {
  const initialAdmission: Partial<AdmissionToCreate> = { unitId, startAt: new Date(), isEndEstimated: true };

  const { create, loading } = useAccommodation();
  const { create: createAdmissionRequest, loading: createAdmissionRequestLoading } = useAccommodationRequest();
  const [error, setError] = useState(false);

  const { openSupport } = useSupport();

  const handleSubmit = async (admission: AdmissionToCreate) => {
    setError(false);
    try {
      if (admission.type === 'DIRECT_ADMISSION') {
        await createAdmissionRequest({
          ...admission,
          requestType: admission.type,
          comment: admission.comment || undefined,
          performerUnitIds: [admission.unitId],
        });
      } else {
        await create({
          ...admission,
          startAt: admission.startAt as Date,
          isEndEstimated: admission.isEndEstimated as boolean,
        });
      }

      toast.success({
        title: i18n.planNewAdmissionSuccessToast.title,
        message: i18n.planNewAdmissionSuccessToast.admissionMessage,
        icon: <Emoji name="okHand" size={24} />,
      });
      onSuccess();
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <DialogHeader title={i18n.createAdmission.title} onClose={onClose} />
      <CreateAdmissionForm
        initialAdmission={initialAdmission}
        onCancel={onClose}
        onSubmit={handleSubmit}
        loading={loading || createAdmissionRequestLoading}
        selectUnitStep={!initialAdmission.unitId}
        availableUnits={availableUnits}
        error={
          error ? (
            <Alert
              title={i18n.dialogError.title}
              severity="error"
              content={
                <Stack spacing={4}>
                  <Typography variant="body1" color="secondary">
                    {i18n.dialogError['messageAdmission']}
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {i18n.dialogError.contactUsFirstPart}
                    <Link
                      component="button"
                      color="secondary"
                      sx={{ verticalAlign: 'baseline', fontWeight: 600 }}
                      onClick={openSupport}
                    >
                      {i18n.dialogError.contactUsLink}
                    </Link>
                    {i18n.dialogError.contactUsLastPart}
                  </Typography>
                </Stack>
              }
              icon={<Emoji name="faceWithColdSweat" size={24} />}
            />
          ) : undefined
        }
      />
    </>
  );
};

const CreateAdmissionDialog: React.FC<CreateAdmissionDialogProps> = ({
  open,
  onClose,
  unitId,
  onSuccess,
  availableUnits,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <CreateAdmissionDialogContent
        unitId={unitId}
        onSuccess={onSuccess}
        onClose={onClose}
        availableUnits={availableUnits}
      />
    </Dialog>
  );
};

export type AdmissionToCreate = {
  unitId: string;
  startAt?: Date | null;
  endAt?: Date | null;
  visitId: string;
  specificities?: AccommodationSpecificity[];
  isEndEstimated?: boolean;
  comment?: string | null;
  directAdmissionOrigin?: string;
  reason?: string;
  type: RequestType;
};

export type RequestType = 'SCHEDULED_ADMISSION' | 'DIRECT_ADMISSION';

export default CreateAdmissionDialog;

import Api, { HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { useEffect, useState } from 'react';

const useHealthCareEnvironmentalCleaning = () => {
  const [cleaningTasks, setCleaningTasks] = useState<HealthcareEnvironmentalCleaning[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let liveSubscription: Parse.LiveQuerySubscription;

    const fetchTasks = async () => {
      const tasks = await getCleaningTasks();
      setCleaningTasks(tasks);
    };

    const fetch = async () => {
      setLoading(true);

      liveSubscription = liveQueryClient.subscribe(
        new Api.Query(HealthcareEnvironmentalCleaning),
        Parse.User.current()?.getSessionToken()
      );

      liveSubscription.on('open', async () => {
        await fetchTasks();
        setLoading(false);
      });

      liveSubscription.on('create', () => fetchTasks());
      liveSubscription.on('enter', () => fetchTasks());
      liveSubscription.on('leave', () => fetchTasks());
      liveSubscription.on('update', () => fetchTasks());
    };

    fetch();

    return () => liveQueryClient.unsubscribe(liveSubscription);
  }, []);

  return {
    loading,
    cleaningTasks: cleaningTasks,
  };
};

const getCleaningTasks = async () => {
  let tasks: HealthcareEnvironmentalCleaning[] = [];
  try {
    tasks = await new Api.Query(HealthcareEnvironmentalCleaning).include('location').findAll();
  } catch (err) {}
  return tasks;
};

export default useHealthCareEnvironmentalCleaning;

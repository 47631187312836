import { LoadingButton } from '@mui/lab';
import { ExternalPatientTransportModal, useExternalTransportModal } from 'common/components';
import { Plus } from 'common/components/Icons';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import { ExternalPatientTransportTable } from 'superadmin/components/ExternalPatientTransportTable/ExternalPatientTransportTable';
import SelectPatientModal from 'superadmin/components/IFrameModal/SelectPatientModal';
import { useExternalPatientTransportsPaginated } from './useExternalPatientTransportsPaginated';

const ExternalTransport = () => {
  const [openPatientSelect, setOpenPatientSelect] = useState(false);
  const { create: handleCreate, close, src, viewDetails, edit } = useExternalTransportModal();

  const handleSelectPatient = (patient?: PatientSearchResult) => {
    setOpenPatientSelect(false);
    if (patient && patient.firstName && patient.lastName) {
      handleCreate({
        ipp: patient.ipp,
        pan: patient.pan,
        lastName: patient.lastName,
        firstName: patient.firstName,
        birthdate: patient.birthdate,
        gender: patient.gender,
      });
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { externalTransports, isLoading, count } = useExternalPatientTransportsPaginated({ page, rowsPerPage });

  return (
    <>
      <PageHeader title="Transport sanitaire">
        <LoadingButton startIcon={<Plus />} onClick={() => setOpenPatientSelect(true)}>
          Créer une demande de transport
        </LoadingButton>
      </PageHeader>
      <PageSection lastSection>
        <ExternalPatientTransportTable
          externalPatientTransports={externalTransports}
          count={count}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          onRowClick={viewDetails}
          onRowUpdate={edit}
        />
      </PageSection>
      <SelectPatientModal
        open={openPatientSelect}
        onCancel={() => setOpenPatientSelect(false)}
        onSubmit={handleSelectPatient}
      />
      <ExternalPatientTransportModal src={src} onClose={close} />
    </>
  );
};

export default ExternalTransport;

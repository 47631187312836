import { ExternalTypeCode, externalTypeCodes } from '@ambuliz/sabri-core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { AreaManagementList } from './components/AreaManagementList';
import { useAreaSearchResults } from './hooks/useAreaSearchResults';

type AreaManagementFilters = {
  name?: string;
  type?: ExternalTypeCode | '';
  externalId?: string;
};

export const AreaManagementSearch = ({ isReadOnly = false }: { isReadOnly?: boolean }) => {
  const [filters, setFilters] = useState<AreaManagementFilters>({
    name: '',
    type: '',
    externalId: '',
  });

  const hasEmptyFilters = Object.values(filters).every((value) => !value);
  const [page] = useState(0);

  const handleChange = (key: keyof AreaManagementFilters, value: string) => {
    setFilters({ ...filters, [key]: value });
  };

  const { areaSearchResults, isLoading } = useAreaSearchResults({ filters, page });

  return (
    <>
      <Stack direction="row" spacing={2}>
        <TextField label="Nom" value={filters.name} onChange={(event) => handleChange('name', event.target.value)} />
        <TextField
          label="Identifant"
          value={filters.externalId}
          onChange={(event) => handleChange('externalId', event.target.value)}
        />
        <Select value={filters.type} onChange={(event) => handleChange('type', event.target.value)}>
          <MenuItem disabled value="">
            <em>Type</em>
          </MenuItem>
          {externalTypeCodes.map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </Select>
        <IconButton onClick={() => setFilters({ name: '', type: '', externalId: '' })}>
          <DeleteIcon />
        </IconButton>
      </Stack>

      <Box marginTop={2}>
        {!isLoading && hasEmptyFilters && <Typography>Entrez un filtre</Typography>}
        {isLoading && !hasEmptyFilters && <Typography>Chargement...</Typography>}
        {!isLoading && !hasEmptyFilters && areaSearchResults.length === 0 && <Typography>Aucun résultat</Typography>}
        {!isLoading && areaSearchResults.length > 0 && (
          <AreaManagementList areas={areaSearchResults} isReadOnly={isReadOnly} />
        )}
      </Box>
    </>
  );
};

import { WebUserRole } from '@ambuliz/sabri-core';
import { QrCodeScanner } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import ExitIcon from 'common/components/Icons/Exit';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { AppRoute, Application, appRoutes } from 'core/routes';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import sabriRoutes from '../../../../sabri/routes';
import superAdminRoutes from '../../../../superadmin/routes';
import Avatar from './Avatar';

type AppBarUserMenuProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onQrCodeMenuItemClick: () => void;
};

const AppBarUserMenu: React.FC<AppBarUserMenuProps> = ({ open, anchorEl, onClose, onQrCodeMenuItemClick }) => {
  const { logout, user, healthCenter, navigationACL } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  const applications = useMemo(() => getUserApplications(navigationACL, user.role), [navigationACL, user.role]);

  const currentApplication = getCurrentApplication(location.pathname);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{ paper: { sx: { width: 240 } } }}
    >
      <Stack alignItems="center" spacing={3} sx={{ padding: 4 }}>
        <Box width={60} height={60}>
          <Avatar />
        </Box>
        <Stack>
          <EllipsisTypography fontWeight={600} textAlign="center" tooltipProps={{ placement: 'left' }}>
            {user.name}
          </EllipsisTypography>
          <EllipsisTypography color="secondary" textAlign="center" maxLines={2} tooltipProps={{ placement: 'left' }}>
            {healthCenter.name}
          </EllipsisTypography>
        </Stack>

        {applications.length > 1 ? (
          <Select
            onChange={(event) => navigate(appRoutes[event.target.value as Application].path)}
            value={currentApplication}
          >
            {applications.map((application) => (
              <MenuItem key={application.path} value={application.route}>
                {application.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Chip label={applications[0]?.label} variant="outlined" size="medium" />
        )}
      </Stack>
      <Divider />
      <ListSubheader>{i18n.myAccount}</ListSubheader>
      <MenuItem onClick={onQrCodeMenuItemClick}>
        <ListItemIcon>
          <QrCodeScanner />
        </ListItemIcon>
        <ListItemText>{i18n.qrCode}</ListItemText>
      </MenuItem>
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText>{i18n.logout}</ListItemText>
      </MenuItem>
    </Menu>
  );
};

const getCurrentApplication = (path: string): Application => {
  if (path.includes(sabriRoutes.root)) {
    return 'sabri';
  } else if (path.includes(superAdminRoutes.root.path)) {
    return 'admin';
  } else {
    return 'kurt';
  }
};

const getUserApplications = (navigationACL: string[], role: WebUserRole): AppRoute[] => {
  const applications = [];

  // If navigation ACL has been set for user
  if (navigationACL.length > 0) {
    if (hasAccessToRoute(navigationACL, 'sabri')) {
      applications.push(appRoutes.sabri);
    }

    if (hasAccessToRoute(navigationACL, 'kurt')) {
      applications.push(appRoutes.kurt);
    }

    if (hasAccessToRoute(navigationACL, 'admin')) {
      applications.push(appRoutes.admin);
    }
  } else {
    if (['ADMIN', 'ADMIN_REGULATOR', 'REGULATOR', 'MANAGER'].includes(role)) {
      applications.push(appRoutes.sabri);
    }

    if (['ADMIN', 'ADMIN_REGULATOR', 'MANAGER', 'BED_MANAGER'].includes(role)) {
      applications.push(appRoutes.kurt);
    }

    if (['ADMIN', 'ADMIN_REGULATOR'].includes(role)) {
      applications.push(appRoutes.admin);
    }
  }

  return applications;
};

export default AppBarUserMenu;

import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { AvatarGroup, Tooltip, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import React from 'react';
import AccommodationSpecificityBadge from './AccommodationSpecificityBadge';

type AccommodationSpecificityBadgesProps = {
  specificities?: AccommodationSpecificity[];
  direction?: React.CSSProperties['flexDirection'];
};

const AccommodationSpecificityBadges: React.FC<AccommodationSpecificityBadgesProps> = ({
  specificities,
  direction,
}) => {
  if (!specificities || specificities.length === 0) {
    return null;
  }

  return (
    <Tooltip
      placement={direction === 'row' ? 'left' : undefined}
      title={specificities.map((specificity, index) => (
        <Typography variant="body2" key={specificity} component="span">
          {index !== specificities.length - 1
            ? `${i18n.bedSpecificities.options[specificity]} · `
            : i18n.bedSpecificities.options[specificity]}
        </Typography>
      ))}
      PopperProps={{ style: { maxWidth: 250 } }}
    >
      <AvatarGroup sx={{ cursor: 'pointer', flexDirection: direction }}>
        {specificities.map((specificity) => (
          <AccommodationSpecificityBadge key={specificity} specificity={specificity} />
        ))}
      </AvatarGroup>
    </Tooltip>
  );
};

export default AccommodationSpecificityBadges;

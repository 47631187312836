import { Accommodation } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { endOfDay } from 'date-fns';
import { useMemo } from 'react';
import { AccommodationSearchFilter } from '../Components/AccommodationSearchFilters';

export const useAccommodationSearch = (filters: AccommodationSearchFilter) => {
  const filterDate = useMemo(() => filters.date || new Date(), [filters]);
  const endOfFilterDate = useMemo(() => endOfDay(filterDate), [filterDate]);

  const { page, rowsPerPage } = filters;

  const query = new Parse.Query(Accommodation)
    .lessThanOrEqualTo('startAt', endOfFilterDate)
    .greaterThanOrEqualTo('endAt', filterDate)
    .exists('visit')
    .containedIn('status', ['PLANNED', 'IN_PROGRESS']);

  const { isLoading: isCountLoading, count } = useParseQueryCount(query);

  const { isLoading: isAccommodationsLoading, results: accommodations } = useParseQuery(
    query
      .limit(rowsPerPage)
      .skip(page * rowsPerPage)
      .include('visit', 'responsibleUnit', 'bed', 'unit', 'unit.areas', 'createdBy')
  );

  return { isLoading: isCountLoading || isAccommodationsLoading, accommodations, accommodationsCount: count };
};

import Api, { InteropMessage, Patient } from '@ambuliz/sabri-core';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ListPagination } from 'common/components';
import { usePagination } from 'common/hooks';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { i18n } from 'common/locale';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { InteropMessageStatusChip } from '../components/InteropMessageStatusChip';

const getInteropMessagesQuery = (patients: Patient[]) => {
  const healthCenter = patients[0]?.healthCenter;
  const pans = patients.map((p) => p.pan);

  return new Api.Query(InteropMessage)
    .equalTo('healthCenter', healthCenter)
    .containedIn('pan', pans)
    .ascending('messageId');
};

const useInteropMessages = (patients: Patient[]) => {
  const query = getInteropMessagesQuery(patients);
  const { count } = useParseQueryCount(query);
  const { page, rowsPerPage, changePage, changeRowsPerPage } = usePagination(count);
  const [messages, setMessages] = useState<InteropMessage[]>();

  const { results: interopMessages, isLoading } = useParseQuery(
    getInteropMessagesQuery(patients)
      .limit(rowsPerPage)
      .skip(page * rowsPerPage),
    {
      enabled: !!patients.length,
      enableLiveQuery: false,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setMessages(interopMessages);
    }
  }, [interopMessages, isLoading]);

  return { messages, count, isLoading, page, rowsPerPage, changePage, changeRowsPerPage };
};

export const PatientInteropMessages = ({ patients }: { patients: Patient[] }) => {
  const { messages, count, page, rowsPerPage, changePage, changeRowsPerPage, isLoading } = useInteropMessages(patients);

  return (
    <Card>
      <CardHeader title="Messages Interop" />
      <CardContent>
        {isLoading && !messages ? (
          <CircularProgress size={20} />
        ) : !messages?.length ? (
          <div>Pas de message...</div>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{i18n.patientHl7.adtTable.status}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.createdAt}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.msgId}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.eventType}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.historicalMovement}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.ipp}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.pan}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.patientClass}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.startMovementTime}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.movementId}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.responsibleUnit}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.unit}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.room}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.bed}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.admittedAt}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.dischargedAt}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.expectedAdmissionDate}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.expectedDischargeDate}</TableCell>
                    <TableCell>{i18n.patientHl7.adtTable.hospitalizationReason}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {messages?.map((message) => (
                    <TableRow key={message.id}>
                      <TableCell>
                        <InteropMessageStatusChip status={message.status} />
                      </TableCell>
                      <TableCell>{formatDate(message.messageDate)}</TableCell>
                      <TableCell>{message.messageId}</TableCell>
                      <TableCell>
                        {message.data?.header?.messageType?.triggerEvent} -{' '}
                        {message.data?.header?.messageType?.messageStructure}
                      </TableCell>
                      <TableCell>{message.data?.movement?.historicalIndicator}</TableCell>
                      <TableCell>
                        {
                          message.data?.patient?.patientIdentifierList?.find(
                            ({ identifierTypeCode }) => identifierTypeCode === 'PI'
                          )?.idNumber
                        }
                      </TableCell>
                      <TableCell>{message.pan}</TableCell>
                      <TableCell>{message.data?.visit?.patientClass}</TableCell>
                      <TableCell>{formatDate(message.data?.movement?.startDate)}</TableCell>
                      <TableCell>{message.movementIds?.toString()}</TableCell>
                      <TableCell>{message.data?.movement?.responsibleWard?.organizationIdentifier}</TableCell>
                      <TableCell>{message.data?.visit?.patientLocation?.pointOfCare}</TableCell>
                      <TableCell>{message.data?.visit?.patientLocation?.room}</TableCell>
                      <TableCell>{message.data?.visit?.patientLocation?.bed}</TableCell>
                      <TableCell>{formatDate(message.data?.visit?.admitDate)}</TableCell>
                      <TableCell>{formatDate(message.data?.visit?.dischargeDate)}</TableCell>
                      <TableCell>{formatDate(message.data?.visit?.expectedAdmitDate)}</TableCell>
                      <TableCell>{formatDate(message.data?.visit?.expectedDischargeDate)}</TableCell>
                      <TableCell>{message.data?.visit?.visitDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ListPagination
              count={count}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18n.movementsByPage}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

const formatDate = (date?: Date) => date && format(date, 'dd/MM/yy - HH:mm');

import Api, { Cloud, HealthCenter } from '@ambuliz/sabri-core';
import { Alert } from '@mui/material';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { PageHeader, PageLoader, PageSection } from 'core/layout';
import { useEffect, useState } from 'react';
import { HealthCenterConfigForm } from 'superadmin/components/HealthCenterConfig';
import ExportStructureButton from './ExportStructureButton';

const getHealthCenter = async () => await new Api.Query(HealthCenter).first();
const getHealthCenterGroups = async () => (await Cloud.getHealthCenterGroups()).map((group) => group.name);

const HealthCenterManagement = () => {
  const [healthCenter, setHealthCenter] = useState<HealthCenter>();
  const [healthCenterGroups, setHealthCenterGroups] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchHealthCenter = async () => {
    setError(false);
    try {
      setHealthCenterGroups(await getHealthCenterGroups());
      setHealthCenter(await getHealthCenter());
    } catch (error) {
      toast.error(i18n.globalError);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHealthCenter();
  }, []);

  if (loading || !healthCenter) {
    return <PageLoader />;
  }

  if (error) {
    return <Alert severity="error">{i18n.globalError}</Alert>;
  }

  return (
    <>
      <PageHeader title={`Établissement - ${healthCenter.name}`}>
        <ExportStructureButton username={healthCenter.username} />
      </PageHeader>
      <PageSection>
        <HealthCenterConfigForm
          healthCenter={healthCenter}
          healthCenterGroups={healthCenterGroups}
          onSuccess={fetchHealthCenter}
        />
      </PageSection>
    </>
  );
};

export default HealthCenterManagement;

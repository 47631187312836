import Api, { Area } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';

type UseHospitalAreasParams = {
  enabled: boolean;
};

export const useHospitalAreas = (params?: UseHospitalAreasParams) => {
  const { results, isLoading } = useParseQuery(new Api.Query(Area).equalTo('externalTypeCode', 'hospital'), {
    enabled: params?.enabled,
  });

  const sortedResults = useMemo(() => results.sort((a, b) => a.name.localeCompare(b.name)), [results]);

  return {
    hospitals: sortedResults,
    isLoading,
  };
};

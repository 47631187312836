import { accommodationSpecificities, AccommodationSpecificity, DelayRule, PatientGender } from '@ambuliz/sabri-core';
import { IconButton, Link, Stack, ToggleButton, Typography, useMediaQuery } from '@mui/material';
import { MultipleSelect, ToggleGroup } from 'common/components';
import { GridView, List as ListView, TimelineView } from 'common/components/Icons';
import ExpandIcon from 'common/components/Icons/Expand';
import { Period } from 'common/components/Timeline';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import { trackMultiSelectEvent } from 'core/analytics/trackEvent';
import { useAuthentication } from 'core/authentication';
import { useAppBarContext } from 'core/layout';
import { DisabledViewTooltip } from 'kurt/components';
import FilterSection, { FilterSectionItem } from 'kurt/components/FilterSection';
import { PatientOutcomeFilter, PatientOutcomeTypeFilter } from 'kurt/components/PatientOutcome';
import { PractitionerQueryParams, PractitionersFilter } from 'kurt/components/Practitioners';
import { SearchPatient } from 'kurt/components/SearchPatient';
import { SpecificityChip } from 'kurt/components/SpecifityChip';
import { useMemo } from 'react';

export type View = 'timeline' | 'list' | 'grid';
export type BedStatus = 'AVAILABLE' | 'BUSY' | 'CLOSED' | 'CLEANING' | 'BOOKED';
export type UnitManagementFilter = {
  specificities: AccommodationSpecificity[];
  statuses: BedStatus[];
  genders: PatientGender[];
  view: View;
  period: Period;
  search?: string;
  practitioners: string[];
  patientOutcomes: PatientOutcomeTypeFilter[];
};

const UnitManagementFilters = ({
  filter,
  onChange,
  filterCount,
  disableSwitchView,
  practitionerQueryParams,
}: {
  filter: UnitManagementFilter;
  onChange: (filter: UnitManagementFilter) => void;
  filterCount: number;
  practitionerQueryParams: PractitionerQueryParams;
  disableSwitchView?: boolean;
}) => {
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { healthCenter } = useAuthentication();
  const { setFullScreen } = useAppBarContext();

  const bedStatusOptions = useBedStatusOptions(healthCenter?.bookedBedDelayRule);
  const specificitiesOptions = accommodationSpecificities.map((s) => ({
    value: s,
    label: i18n.accommodationSpecificities[s],
  }));

  const resetFilters = () => {
    onChange({
      ...filter,
      specificities: [],
      statuses: [],
      genders: [],
      search: '',
      practitioners: [],
      patientOutcomes: [],
    });
  };

  const hasActivFilters =
    filter.specificities.length > 0 ||
    filter.statuses.length > 0 ||
    filter.genders.length > 0 ||
    filter.search ||
    filter.practitioners.length > 0 ||
    filter.patientOutcomes.length > 0;

  return (
    <FilterSection>
      <FilterSectionItem.Filters>
        <SearchPatient value={filter.search} onChange={(search) => onChange({ ...filter, search })} />
        <MultipleSelect
          values={filter.statuses}
          onChange={(values) => {
            trackMultiSelectEvent({
              eventType: 'unit_management_filter_select_changed',
              currentValues: filter.statuses,
              newValues: values,
              options: bedStatusOptions,
            });
            onChange({ ...filter, statuses: values as BedStatus[] });
          }}
          label={i18n.currentBedStatus}
          options={bedStatusOptions}
        />
        <MultipleSelect
          values={filter.specificities}
          onChange={(values) => {
            trackMultiSelectEvent({
              eventType: 'unit_management_filter_select_changed',
              currentValues: filter.specificities,
              newValues: values,
              options: specificitiesOptions,
            });
            onChange({ ...filter, specificities: values });
          }}
          label={i18n.specificities}
          options={specificitiesOptions}
          renderOption={(value) => <SpecificityChip specificity={value} sx={{ marginLeft: 1 }} />}
        />
        <MultipleSelect
          values={filter.genders}
          onChange={(values) => onChange({ ...filter, genders: values })}
          label={i18n.bedGender}
          options={bedGenderOptions.map((option) => ({
            value: option.value,
            label: i18n.bedGenders[option.value],
          }))}
        />
        <PractitionersFilter
          practitionerQueryParams={practitionerQueryParams}
          value={filter.practitioners}
          onChange={(practitioners) => onChange({ ...filter, practitioners })}
        />
        <PatientOutcomeFilter
          onChange={(patientOutcomes) => onChange({ ...filter, patientOutcomes })}
          values={filter.patientOutcomes}
        />
        {hasActivFilters && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Link component="button" onClick={resetFilters}>
              <Typography color={color.grey[80]}>{i18n.clearFilters}</Typography>
            </Link>
            <Typography color="secondary">{i18n.bedsCount(filterCount)}</Typography>
          </Stack>
        )}
      </FilterSectionItem.Filters>
      <FilterSectionItem.Switchers>
        {filter.view === 'timeline' && (
          <ToggleGroup value={filter.period} onChange={(_, value) => onChange({ ...filter, period: value })}>
            <ToggleButton value="day">{i18n.day}</ToggleButton>
            <ToggleButton value="week">{i18n.week}</ToggleButton>
          </ToggleGroup>
        )}
        <Stack gap={2} alignItems="center" direction="row">
          {matchUpMd && <Typography color="primary">{i18n.unitView(filter.view)}</Typography>}
          <ToggleGroup value={filter.view} onChange={(_, value) => onChange({ ...filter, view: value })}>
            {disableSwitchView ? (
              <DisabledViewTooltip>
                <GridView />
              </DisabledViewTooltip>
            ) : (
              <ToggleButton value="grid">
                <GridView />
              </ToggleButton>
            )}
            {disableSwitchView ? (
              <DisabledViewTooltip>
                <TimelineView />
              </DisabledViewTooltip>
            ) : (
              <ToggleButton value="timeline">
                <TimelineView />
              </ToggleButton>
            )}
            <ToggleButton value="list">
              <ListView />
            </ToggleButton>
          </ToggleGroup>
        </Stack>

        <IconButton variant="outlined" size="large" shape="rounded" onClick={() => setFullScreen(true)}>
          <ExpandIcon />
        </IconButton>
      </FilterSectionItem.Switchers>
    </FilterSection>
  );
};

export default UnitManagementFilters;

const useBedStatusOptions = (delayRule?: DelayRule) => {
  return useMemo(() => {
    const bedStatusOptions = [
      {
        value: 'AVAILABLE',
        label: i18n.bedStatuses.AVAILABLE,
      },
      {
        value: 'CLEANING',
        label: i18n.bedStatuses.CLEANING,
      },
      {
        value: 'BUSY',
        label: i18n.bedStatuses.BUSY,
      },
      {
        value: 'CLOSED',
        label: i18n.bedStatuses.CLOSED,
      },
    ];

    if (delayRule) {
      bedStatusOptions.splice(1, 0, {
        value: 'BOOKED',
        label: i18n.bedStatuses.BOOKED,
      });
    }

    return bedStatusOptions;
  }, [delayRule]);
};

const bedGenderOptions: { value: PatientGender; label: string }[] = [
  { value: 'MALE', label: i18n.patientGenders.MALE },
  { value: 'FEMALE', label: i18n.patientGenders.FEMALE },
  { value: 'UNKNOWN', label: i18n.patientGenders.UNKNOWN },
];

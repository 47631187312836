import { Accommodation } from '@ambuliz/sabri-core';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination } from 'common/components';
import { useState } from 'react';
import { AccommodationResultRow } from './AccommodationResultRow';
import { LoadingRow } from './LoadingRow';

export type AccommodationResultListProps = {
  accommodations: Accommodation[];
  count: number;
  onChange: (page: number, rowsPerPage: number) => void;
  isLoading?: boolean;
};

export const AccommodationResultList = ({
  accommodations,
  count,
  onChange,
  isLoading,
}: AccommodationResultListProps) => {
  const defaultRowsPerPage = 30;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChange(newPage, rowsPerPage);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onChange(0, newRowsPerPage);
  };

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={250}>Patient</TableCell>
              <TableCell>Pan</TableCell>
              <TableCell>Ipp</TableCell>
              <TableCell>Specificités</TableCell>
              <TableCell width={100}>Lit</TableCell>
              <TableCell>Unité</TableCell>
              <TableCell>Unité responsable</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from({ length: 10 }).map((_, index) => <LoadingRow />)
              : accommodations.map((accommodation) => <AccommodationResultRow accommodation={accommodation} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <ListPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        count={count}
      />
    </Paper>
  );
};

import { Skeleton, TableCell, TableRow } from '@mui/material';

export const LoadingRow = () => (
  <TableRow>
    {Array.from({ length: 10 }).map((_, index) => (
      <TableCell>
        <Skeleton key={index} variant="rounded" height={25} animation="wave" />
      </TableCell>
    ))}
  </TableRow>
);

import { Unit, UnitType } from '@ambuliz/sabri-core';
import { Chip, ChipProps, Stack, TableCell } from '@mui/material';

const unitTypeColor: Record<UnitType, ChipProps['color']> = {
  ACCOMMODATION: 'info',
  EMERGENCY: 'error',
  MEDICAL: 'success',
  OTHER: 'secondary',
};

type UnitTableCellProps = {
  unit?: Unit;
};

export const UnitTableCell = ({ unit }: UnitTableCellProps) => {
  return (
    <TableCell>
      {unit && (
        <Stack direction="row" gap={1}>
          <Chip label={unit.name} color={unitTypeColor[unit.type]} />
          {unit.areas?.map((area) => area.externalIds?.map((id) => <Chip label={id} color="secondary" />))}
          {unit.department && <Chip label={unit.department} color="primary" variant="rounded" />}
        </Stack>
      )}
    </TableCell>
  );
};

import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';

import { useThesaurusPaginated } from './useThesaurusPaginated';
import { ThesaurusTable } from 'superadmin/components/ThesaurusTable/ThesaurusTable';
import { i18n } from 'common/locale';

const Thesaurus = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { thesaurus, isLoading, count } = useThesaurusPaginated({ page, rowsPerPage });

  return (
    <>
      <PageHeader title={i18n.thesaurus}></PageHeader>
      <PageSection lastSection>
        <ThesaurusTable
          thesaurus={thesaurus}
          count={count}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
        />
      </PageSection>
    </>
  );
};

export default Thesaurus;

import Api, { Patient, PatientIdentity } from '@ambuliz/sabri-core';
import { Autocomplete, Button, Chip, CircularProgress, Grid, TextField } from '@mui/material';
import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PatientTable from './PatientTable';

type OrderByOption = {
  key: string;
  direction: 'asc' | 'desc';
};

type FetchPatientsParams = {
  orderBy?: OrderByOption;
  page?: number;
  ids?: string[];
  pans?: string[];
  ipps?: string[];
  rowsPerPage?: number;
};

const usePatients = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async ({
    ids = [],
    pans = [],
    ipps = [],
    page = 0,
    orderBy,
    rowsPerPage = 20,
  }: FetchPatientsParams) => {
    setIsLoading(true);
    const query = new Api.Query(PatientIdentity)
      .withCount()
      .limit(rowsPerPage)
      .skip(page * rowsPerPage);

    if (ids.length > 0) {
      query.containedIn('objectId', ids);
    }

    if (pans.length > 0) {
      query.matchesKeyInQuery('objectId', 'patient.objectId', new Api.Query(Patient).containedIn('pan', pans));
    }

    if (ipps.length > 0) {
      query.matchesKeyInQuery('objectId', 'patient.objectId', new Api.Query(Patient).containedIn('ipp', ipps));
    }

    if (orderBy) {
      if (orderBy.direction === 'desc') {
        query.descending(orderBy.key);
      } else {
        query.ascending(orderBy.key);
      }
    }
    const { count, results } = (await query.find()) as unknown as { count: number; results: PatientIdentity[] };
    setIsLoading(false);
    return { count, results };
  };

  return {
    fetch,
    isLoading,
  };
};

const PatientSearchPage = () => {
  const [inputIdValue, setInputIdValue] = useState<string>('');
  const [ids, setIds] = useState<string[]>([]);
  const [inputIppValue, setInputIppValue] = useState<string>('');
  const [ipps, setIpps] = useState<string[]>([]);
  const [inputPanValue, setInputPanValue] = useState<string>('');
  const [pans, setPans] = useState<string[]>([]);
  const [patients, setPatients] = useState<PatientIdentity[]>([]);
  const [total, setTotal] = useState(0);

  const [orderBy, setOrderBy] = useState<OrderByOption>({ key: 'createdAt', direction: 'desc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const { fetch, isLoading } = usePatients();

  const fetchPatients = async (params?: { page?: number; orderBy?: OrderByOption; rowsPerPage?: number }) => {
    if (params?.page !== undefined) {
      setPage(params.page);
    }
    if (params?.orderBy !== undefined) {
      setOrderBy(params.orderBy);
    }
    if (params?.rowsPerPage !== undefined) {
      setRowsPerPage(params.rowsPerPage);
    }

    const { count, results } = await fetch({
      ids: inputIdValue ? [...ids, inputIdValue] : ids,
      pans: inputPanValue ? [...pans, inputPanValue] : pans,
      ipps: inputIppValue ? [...ipps, inputIppValue] : ipps,
      page: params?.page ?? page,
      orderBy: params?.orderBy ?? orderBy,
      rowsPerPage: params?.rowsPerPage ?? rowsPerPage,
    });
    setPatients(results);
    setTotal(count);
  };

  return (
    <>
      <PageHeader title="Patients" />
      <PageSection>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              value={ids}
              onChange={(_, value) => setIds(value as string[])}
              inputValue={inputIdValue}
              onInputChange={(_, value) => setInputIdValue(value)}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => <Chip label={value} {...getTagProps({ index })} />)
              }
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="ID" />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              value={pans}
              onChange={(_, value) => setPans(value as string[])}
              inputValue={inputPanValue}
              onInputChange={(_, value) => setInputPanValue(value)}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => <Chip label={value} {...getTagProps({ index })} />)
              }
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="PAN" />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              value={ipps}
              onChange={(_, value) => setIpps(value as string[])}
              inputValue={inputIppValue}
              onInputChange={(_, value) => setInputIppValue(value)}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => <Chip label={value} {...getTagProps({ index })} />)
              }
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="IPP" />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button onClick={() => fetchPatients({ page: 0 })}>Search</Button>
          </Grid>
        </Grid>
      </PageSection>
      <PageSection>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <PatientTable
            patients={patients}
            orderBy={orderBy}
            onOrderByChange={(orderBy) => fetchPatients({ orderBy })}
            onPageChange={(page) => fetchPatients({ page })}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(rowsPerPage) => fetchPatients({ rowsPerPage, page: 0 })}
            total={total}
          />
        )}
      </PageSection>

      <Outlet />
    </>
  );
};

export default PatientSearchPage;

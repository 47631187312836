import Api, { Appointment } from '@ambuliz/sabri-core';
import {
  Box,
  Card,
  CardContent,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { PageHeader, PageSection } from 'core/layout';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import PatientAnonymizer from '../anonymizer';
import { AppointmentDate } from '../components';

const RESULTS_PER_PAGE = 10;

const AppointmentsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [dates, setDates] = useState<{ start: Date | null; end: Date | null }>({ start: null, end: null });

  const handleDateChange = (key: 'start' | 'end') => (value: Date | null) => {
    if (value && isValid(value)) {
      setDates((state) => ({ [key]: value, ...state }));
    }
  };

  const handleKeyboardDateChange =
    (key: 'start' | 'end') => (value: Date | null, context: FieldChangeHandlerContext<unknown>) => {
      if (!context.validationError) {
        handleDateChange(key)(value);
      }
    };

  const { pageCount, appointments, count } = useAppointmentsByPage(currentPage, dates.start, dates.end);
  return (
    <Stack spacing={4}>
      <PageHeader title="Rendez-vous">
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Entre le"
            value={dates.start}
            onChange={(value) => handleDateChange('start')(value)}
            slotProps={{
              field: {
                onChange: handleKeyboardDateChange('start'),
              },
            }}
          />
          <DatePicker
            label="et le"
            value={dates.end}
            onChange={(value) => handleDateChange('end')(value)}
            slotProps={{
              field: {
                onChange: handleKeyboardDateChange('end'),
              },
            }}
          />
        </Stack>
      </PageHeader>
      <PageSection>
        <Card>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Début</TableCell>
                  <TableCell>Fin</TableCell>
                  <TableCell>Lieu</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Commentaire</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointments.map((appointment) => (
                  <TableRow key={appointment.id}>
                    <TableCell width="12%">
                      <AppointmentDate date={appointment.start} />
                    </TableCell>
                    <TableCell width="12%">
                      <AppointmentDate date={appointment.end} />
                    </TableCell>
                    <TableCell width="12%">{appointment.location?.name}</TableCell>
                    <TableCell>{PatientAnonymizer(appointment.patient).name}</TableCell>
                    <TableCell>
                      <Typography>{appointment.status}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{appointment.comment}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </PageSection>

      <PageSection>
        <Box display="flex" justifyContent="space-between" alignContent="center">
          <Typography>{count} rendez-vous</Typography>
          <Pagination count={pageCount} onChange={(_, page) => setCurrentPage(page - 1)} />
        </Box>
      </PageSection>
    </Stack>
  );
};

const useAppointmentsByPage = (page: number, from: Date | null, to: Date | null) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const query = new Api.Query(Appointment)
        .include('location.name', 'patient.firstName', 'patient.lastName')
        .skip(page * RESULTS_PER_PAGE)
        .limit(RESULTS_PER_PAGE)
        .withCount();

      if (from) {
        query.greaterThanOrEqualTo('end', startOfDay(from));
      }

      if (to) {
        query.lessThanOrEqualTo('start', endOfDay(to));
      }

      const { count, results } = (await query.find()) as any;
      setCount(count);
      setPageCount(Math.ceil(count / RESULTS_PER_PAGE));
      setAppointments(results);
    };

    fetch();
  }, [page, from, to]);

  return {
    count,
    pageCount,
    appointments,
  };
};

export default AppointmentsPage;

import { NavigationACL, NavigationRoute, UserRole } from '@ambuliz/sabri-core';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useMemo } from 'react';

type Page = {
  label: string;
  route: NavigationRoute;
  pages?: Page[];
};

const appPages: Page[] = [
  {
    label: 'Gestion des lits',
    route: 'kurt',
    pages: [
      { label: 'Territoire', route: 'kurt.territory' },
      { label: 'Établissement', route: 'kurt.healthCenter' },
      { label: 'Mes lits', route: 'kurt.unit' },
      { label: 'Mouvements', route: 'kurt.movements' },
      {
        label: 'Urgences',
        route: 'kurt.emergency',
      },
    ],
  },
  {
    label: 'Brancardage',
    route: 'sabri',
    pages: [
      { label: 'Missions', route: 'sabri.fares' },
      { label: 'Équipe', route: 'sabri.team' },
    ],
  },
  {
    label: 'Administration',
    route: 'admin',
    pages: [
      { label: 'Emplacements', route: 'admin.areas' },
      { label: 'Unités', route: 'admin.units' },
      { label: 'Établissement', route: 'admin.healthCenter' },
      { label: 'Utilisateurs', route: 'admin.users' },
      { label: 'Patients', route: 'admin.patients' },
      { label: 'Rendez-vous', route: 'admin.appointments' },
      { label: 'Transport sanitaire', route: 'admin.externalTransports' },
      { label: 'Bionettoyage', route: 'admin.healthcareEnvironmentalCleaning' },
      { label: 'Anomalies', route: 'admin.anomalies' },
      { label: 'Urgences', route: 'admin.emergencies' },
      { label: 'Synthèse', route: 'admin.overview' },
    ],
  },
];

const findParent = (route: NavigationRoute, pages: Page[]): Page | undefined => {
  if (pages.length === 0) {
    return undefined;
  }

  for (const page of pages) {
    if (page.pages?.find((page) => page.route === route)) {
      return page;
    }

    const parent = findParent(route, page.pages || []);

    if (parent) {
      return parent;
    }
  }
};

type NavigationAclSelectProps = {
  value: NavigationACL;
  onChange: (value: NavigationACL) => void;
  role?: UserRole;
};

const NavigationAclSelect = ({ value: acl = [], onChange: setACL, role = 'MANAGER' }: NavigationAclSelectProps) => {
  const pages = useMemo(() => {
    if (['ADMIN', 'ADMIN_REGULATOR'].includes(role)) {
      return appPages;
    }

    return appPages.filter((page) => page.route !== 'admin');
  }, [role]);

  const isChecked = (route: NavigationRoute) => {
    for (const page of acl) {
      if (page.length < route.length && route.startsWith(page)) {
        return true;
      }
    }

    return acl.includes(route);
  };

  const isIndeterminate = (route: NavigationRoute) => {
    if (acl.includes(route)) {
      return false;
    }
    for (const aclRoute of acl) {
      if (aclRoute.startsWith(route)) {
        return true;
      }
    }
  };

  const handleChange = (route: NavigationRoute) => () => {
    const parent = findParent(route, pages);
    let newACL = acl;

    if (isChecked(route) && acl.includes(route)) {
      newACL = acl.filter((aclRoute) => !aclRoute.startsWith(route));
    } else if (isChecked(route) && !acl.includes(route)) {
      if (parent) {
        const newAcl = new Set(acl);
        for (const page of parent.pages || []) {
          newAcl.add(page.route);
        }

        newAcl.delete(parent.route);
        newAcl.delete(route);

        newACL = Array.from(newAcl);
      }
    }

    if (!isChecked(route) && !acl.includes(route)) {
      const newAcl = new Set([...acl, route]);

      if (parent) {
        const includesAllChildren = parent.pages?.every((page) => newAcl.has(page.route));

        if (includesAllChildren) {
          newAcl.add(parent.route);
          for (const page of parent.pages || []) {
            newAcl.delete(page.route);
          }
        }
      }
      newACL = Array.from(newAcl);
    }

    if (newACL.includes(route)) {
      newACL = newACL.filter((acl) => {
        const isChild = acl.split('.').length > route.split('.').length && acl.startsWith(route);
        return !isChild;
      });
    }

    setACL(newACL);
  };

  return (
    <Stack spacing={1}>
      {pages.map((page) => {
        return (
          <Stack key={page.route}>
            <FormControlLabel
              key={page.route}
              label={page.label}
              control={
                <Checkbox
                  checked={isChecked(page.route)}
                  indeterminate={isIndeterminate(page.route)}
                  onChange={handleChange(page.route)}
                />
              }
            />
            {page.pages?.map((subPage) => {
              return (
                <Box pl={4} key={subPage.route}>
                  <FormControlLabel
                    label={subPage.label}
                    control={
                      <Checkbox
                        checked={isChecked(subPage.route)}
                        indeterminate={isIndeterminate(subPage.route)}
                        onChange={handleChange(subPage.route)}
                      />
                    }
                  />
                </Box>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default NavigationAclSelect;

import Api, { Porter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

export const useAvailablePorters = (enabled?: boolean) => {
  const { results: availablePorters, isLoading } = useParseQuery(
    new Api.Query(Porter).containedIn('status', ['OFFLINE', 'AVAILABLE', 'ON_BREAK', 'BUSY']),
    { enabled }
  );

  return { availablePorters, isLoading };
};

import { IUser, UserRole } from '@ambuliz/sabri-core';
import { useDebounce } from 'common/hooks';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAuthentication } from 'core/authentication';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { usePagination } from '../hooks/usePagination';
import { NormalizedUser, UserCreateForm, UserManagementContextValue, UserUpdateForm } from './UserManagement.model';
import { createUser, updateUser, useBuildUserQuery } from './UserManagement.queries';

const normalizeUser = (user: IUser & { id: string }): NormalizedUser => ({
  objectId: user.id,
  role: user.role,
  username: user.username,
  unit: user.unit?.id || undefined,
  unitName: user.unit?.name || undefined,
  firstName: user.porter?.firstName || undefined,
  lastName: user.porter?.lastName || undefined,
  password: user.password,
  units: user.units?.map((unit) => unit.id) || [],
  notificationConfigurations: user.notificationConfigurations || undefined,
  navigationACL: user.navigationACL || undefined,
  isReadOnly: user.isReadOnly || undefined,
  whitelistedIPs: user.whitelistedIPs || undefined,
});

export const UserManagementContext = createContext({} as UserManagementContextValue);

export const UserManagementContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { user } = useAuthentication();
  const pagination = usePagination();
  const [name, setName] = useState('');
  const [roles, setRoles] = useState<UserRole[]>([]);
  const debouncedName = useDebounce(name, 300);

  const findUsersQuery = useBuildUserQuery(pagination, user, debouncedName, roles);

  useEffect(() => {
    findUsersQuery.count().then(pagination.setCount);
  }, [pagination.setCount, findUsersQuery]);

  const { results, isLoading, refetch } = useParseQuery(findUsersQuery, { enableLiveQuery: false });

  const users = useMemo(() => results.map(normalizeUser), [results]);

  const handleUpdateUser = async (user: UserUpdateForm) => {
    await updateUser(user);
    await refetch();
  };

  const handleCreateUser = async (user: UserCreateForm) => {
    await createUser(user);
    await refetch();
  };

  return (
    <UserManagementContext.Provider
      value={{
        pagination,
        filters: {
          roles,
          setRoles,
          name,
          setName,
        },
        users,
        loading: isLoading,
        createUser: handleCreateUser,
        updateUser: handleUpdateUser,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

import { SvgIcon, SvgIconProps } from '@mui/material';

const Isolated: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7.895C0 3.535 3.582 0 8 0s8 3.535 8 7.895a.713.713 0 0 1-.718.709.713.713 0 0 1-.718-.709c0-3.577-2.939-6.478-6.564-6.478s-6.564 2.9-6.564 6.478a.713.713 0 0 1-.718.709A.713.713 0 0 1 0 7.895Zm5.574-2.774a3.505 3.505 0 0 1 2.425-.965c.909 0 1.781.346 2.426.965a3.24 3.24 0 0 1 1.008 2.338 3.24 3.24 0 0 1-1.109 2.432c.57.256 1.094.607 1.548 1.042a5.166 5.166 0 0 1 1.608 3.728v.654a.695.695 0 0 1-.706.685h-9.55a.695.695 0 0 1-.705-.685v-.654c0-1.4.58-2.74 1.608-3.728a5.485 5.485 0 0 1 1.548-1.042 3.241 3.241 0 0 1-1.11-2.431c0-.88.364-1.72 1.009-2.34Zm2.425.404c-.539 0-1.054.206-1.433.57-.378.363-.59.854-.59 1.364 0 .51.212 1.002.59 1.365.379.364.894.57 1.433.57.54 0 1.055-.206 1.434-.57.378-.363.59-.854.59-1.365 0-.51-.212-1.001-.59-1.365a2.071 2.071 0 0 0-1.434-.569Zm0 5.238a4.162 4.162 0 0 0-2.88 1.144 3.817 3.817 0 0 0-1.189 2.724h8.139c-.008-1.02-.434-2-1.19-2.724A4.162 4.162 0 0 0 8 10.763Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Isolated;

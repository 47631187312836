import { ACCESSIBLE_ROLES } from '@ambuliz/sabri-core';
import { Button, Drawer, Grid, MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FilterSelect } from 'common/components/Form';
import { i18n } from 'common/locale';
import { User, UserHealthCenter } from 'core/authentication';
import { PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import UsersTable from './components/Table';
import UserForm from './components/UserForm';
import { NormalizedUser, UserCreateFormState, UserUpdateFormState, UsersQueryFilters } from './context';
import { Pagination } from './hooks/usePagination';

interface Props {
  connectedUser: User;
  users: NormalizedUser[];
  filters: UsersQueryFilters;
  pagination: Pagination;
  healthCenter: UserHealthCenter;
  usersLoading?: boolean;
  onCreateUser: (user: UserCreateFormState) => Promise<void>;
  onUpdateUser: (user: UserUpdateFormState) => Promise<void>;
}

export const UserManagementComponent = ({
  connectedUser,
  healthCenter,
  filters,
  pagination,
  users,
  usersLoading,
  onCreateUser,
  onUpdateUser,
}: Props) => {
  const classes = useStyles();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const accessibleRoles = ACCESSIBLE_ROLES[connectedUser.role];

  const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    filters.setName(e.currentTarget.value);

  return (
    <>
      <PageHeader title={i18n.users}>
        <Button onClick={() => setCreateModalOpen(true)}>{i18n.createUser}</Button>
      </PageHeader>
      <PageSection>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <FilterSelect label="Rôle" value={filters.roles} multiple onChange={filters.setRoles}>
              {accessibleRoles.map((role) => (
                <MenuItem value={role} key={role}>
                  {i18n[role]}
                </MenuItem>
              ))}
            </FilterSelect>
          </Grid>
          <Grid item>
            <TextField value={filters.name} label="Nom d'utilisateur" onChange={handleNameFilterChange}></TextField>
          </Grid>
        </Grid>
      </PageSection>
      <PageSection withBackground>
        <UsersTable
          pagination={pagination}
          healthCenterId={healthCenter.id}
          users={users}
          loading={usersLoading}
          onUpdate={onUpdateUser}
        />
      </PageSection>
      <Drawer
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        anchor="right"
        classes={{ paper: classes.root }}
      >
        <UserForm onCancel={() => setCreateModalOpen(false)} onCreateSubmit={onCreateUser} />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: '30%',
    maxWidth: '30%',
  },
});

import {
  Accommodation,
  AccommodationRequest,
  AccommodationSpecificity,
  AnomalyType,
  PatientGender,
  PerformerRequest,
  Practitioner,
  RequestType,
} from '@ambuliz/sabri-core';
import { formatName } from 'common/utils';

export type MovementStatus =
  | 'accommodation_to_assign' // accommodation planned sans lit
  | 'accommodation_assigned' // accommodation planned avec lit
  | 'accommodation_admitted' // accommodation IN_PROGRESS ou COMPLETED
  | 'accommodation_planned_discharge' //  IN_PROGRESS avec isEndEstimated = true
  | 'accommodation_confirmed_discharge' // accommodation IN_PROGRESS avec isEndEstimated = false
  | 'accommodation_discharged' // accommodation COMPLETED
  | 'incoming_mutation_requested'
  | 'outgoing_mutation_requested'
  | 'outgoing_mutation_blocked';

export const getAccommodationMovementStatus = (accommodation: Accommodation, isDischarge?: boolean): MovementStatus => {
  if (isDischarge) {
    if (accommodation.status === 'COMPLETED') {
      return 'accommodation_discharged';
    }
    return accommodation.isEstimatedEnd ? 'accommodation_planned_discharge' : 'accommodation_confirmed_discharge';
  } else {
    if (accommodation.status === 'COMPLETED') {
      return 'accommodation_admitted';
    }

    if (accommodation.status === 'IN_PROGRESS' && !accommodation.bed) {
      return 'accommodation_admitted';
    }
    if (!accommodation.bed) {
      return 'accommodation_to_assign';
    }
    if (accommodation.status === 'IN_PROGRESS') {
      return 'accommodation_admitted';
    }
    return 'accommodation_assigned';
  }
};

export const getRequestMovementStatus = (request: AccommodationRequest, isOutgoing?: boolean): MovementStatus => {
  if (request.status === 'REJECTED') {
    return 'outgoing_mutation_blocked';
  }

  return isOutgoing ? 'outgoing_mutation_requested' : 'incoming_mutation_requested';
};

type MovementRow = {
  id: string;
  status: MovementStatus;
  date: Date;
  admissionDate?: Date;
  dischargeDate?: Date;
  bed?: string;
  bedId?: string;
  visitId: string;
  isVisitDischarged?: boolean;
  patientName: string;
  patientGender: PatientGender;
  patientBirthdate: Date;
  from?: string;
  to?: string;
  duration?: number;
  specificities: AccommodationSpecificity[];
  reason?: string;
  comment?: string;
  performerRequests?: PerformerRequest[];
  isAdmissionDateOutdated?: boolean;
  isDischargeDateOutdated?: boolean;
  hasMutation?: boolean;
  anomalies?: { type: AnomalyType }[];
  unitName?: string;
  unitId?: string;
  isCompleted?: boolean;
  directAdmissionOrigin?: string;
  requestType?: RequestType;
  practitioners?: Practitioner[];
};

const mapRequestToRow = (request: AccommodationRequest, isOutgoing?: boolean): MovementRow | undefined => {
  return request.visit
    ? {
        date: request.createdAt,
        id: request.id,
        visitId: request.visit.id,
        patientName: formatName(
          request.visit.firstName,
          request.visit.lastName,
          request.visit.legalName,
          request.visit.legalFirstName
        ),
        patientBirthdate: request.visit.birthdate,
        patientGender: request.visit.gender,
        specificities: request.specificities || [],
        reason: request.reason,
        comment: request.comment,
        performerRequests: request.performerRequests || [],
        status: getRequestMovementStatus(request, isOutgoing),
        requestType: request.requestType,
        directAdmissionOrigin: request.directAdmissionOrigin,
      }
    : undefined;
};

const mapAccommodationToRow = (
  accommodation: Accommodation,
  anomalies: { type: AnomalyType }[],
  isDischarged?: boolean
): MovementRow => {
  const admissionDate = new Date(accommodation.startAt);
  const dischargeDate = accommodation.endAt ? new Date(accommodation.endAt) : undefined;
  const status = getAccommodationMovementStatus(accommodation, isDischarged);
  return {
    date: isDischarged && dischargeDate ? dischargeDate : admissionDate,
    admissionDate,
    dischargeDate,
    id: accommodation.id,
    bed: accommodation.bed?.name,
    bedId: accommodation.bed?.id,
    unitId: accommodation.unit?.id,
    visitId: accommodation.visit!.id,
    patientName: formatName(
      accommodation.visit?.firstName,
      accommodation.visit?.lastName,
      accommodation.visit?.legalName,
      accommodation.visit?.legalFirstName
    ),
    unitName: accommodation.unit.name,
    hasMutation: !!accommodation.basedOn,
    patientBirthdate: accommodation.visit!.birthdate,
    patientGender: accommodation.visit!.gender,
    duration: dischargeDate ? dischargeDate.getTime() - admissionDate.getTime() : undefined,
    specificities: accommodation.specificities || [],
    reason: '',
    comment: accommodation.comment,
    status,
    anomalies,
    isCompleted: accommodation.status === 'COMPLETED',
    requestType: accommodation.basedOn?.requestType,
    practitioners: accommodation.practitioners,
    isVisitDischarged: accommodation.visit?.isDischarged || false,
  };
};

export { mapAccommodationToRow, mapRequestToRow };
export type { MovementRow };

import { Patient } from '@ambuliz/sabri-core';
import { formatName } from 'common/utils';
import MovementsActionForm from 'kurt/pages/Movements/MovementsList/MovementsListRow/MovementsActions';
import { AccommodationAction } from '../PatientFlowKanban/VisitActions';
import { StepItem } from './context/Step';

type DetailsDialogActionFormProps = {
  action: AccommodationAction;
  step: StepItem;
  visit?: Patient;
  onClose: () => void;
  onCancel: () => void;
  onSuccess?: () => void;
};

const DetailsDialogActionForm = ({
  action,
  step,
  visit,
  onClose,
  onCancel,
  onSuccess,
}: DetailsDialogActionFormProps) => {
  const id = getId(step, action);
  const bedId = getBedIdFromStep(step);
  const unitId: string | undefined = getUnitIdFromStep(step);

  const performerRequests = (step.type === 'mutation' ? step.step?.performerRequests : []) || [];
  const patient = getPatientFromVisit(visit);

  return (
    <MovementsActionForm
      action={action}
      open={!!action}
      onClose={onClose}
      onSuccess={onSuccess || onClose}
      bedId={bedId}
      unitId={unitId}
      admissionDate={step.start}
      dischargeDate={step.end}
      performerRequests={performerRequests}
      nextAccommodationId={step.type === 'accommodation' || step.type === 'mutation' ? step.destination?.id : undefined}
      previousAccommodationId={step.type === 'mutation' ? step.origin?.id : undefined}
      patient={patient}
      specificities={step.type === 'accommodation' || step.type === 'mutation' ? step.step.specificities || [] : []}
      id={id}
      comment={step.type === 'accommodation' || step.type === 'mutation' ? step.step?.comment : undefined}
      onCancel={onCancel}
    />
  );
};

const getId = (step: StepItem, action: AccommodationAction) => {
  if (step.type === 'mutation' && ['CONFIRM_ADMISSION', 'CANCEL_BED', 'SET_BED', 'UPDATE_BED'].includes(action)) {
    return step.destination?.id || '';
  }
  return step.step.id;
};

const getBedIdFromStep = (step: StepItem) => {
  switch (step.type) {
    case 'accommodation':
      return step.step.bed?.id || step.destination?.bed?.id;
    case 'mutation':
      return step.destination?.bed?.id;
    case 'availableBed':
      return step.step.id;
    default:
      return undefined;
  }
};

const getUnitIdFromStep = (step: StepItem) => {
  switch (step.type) {
    case 'accommodation':
      return step.step?.unit?.id || step.destination?.unit?.id;
    case 'mutation':
      return (
        step.destination?.unit?.id ||
        step.step.performerRequests?.find((request) => ['REQUESTED', 'ACCEPTED'].includes(request.status))?.performer.id
      );
    case 'availableBed':
      return step.unitId;
    default:
      return undefined;
  }
};

const getPatientFromVisit = (visit?: Patient) => {
  if (visit) {
    return {
      id: visit.id,
      pan: visit.pan,
      ipp: visit.ipp,
      birthdate: visit.birthdate,
      name: formatName(visit.firstName, visit.lastName, visit.legalName, visit.legalFirstName),
      gender: visit.gender,
    };
  }

  return undefined;
};

export default DetailsDialogActionForm;

import { accommodationSpecificities, AccommodationSpecificity } from '@ambuliz/sabri-core';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { FilterSelect } from 'common/components/Form';
import { i18n } from 'common/locale';
import { SpecificityChip } from 'kurt/components/SpecifityChip';

type AccommodationSpecificitiesSelectProps = {
  value: AccommodationSpecificity[];
  onChange: (value: AccommodationSpecificity[]) => void;
  label?: string;
  loading?: boolean;
};

const AccommodationSpecificitiesSelect: React.FC<AccommodationSpecificitiesSelectProps> = ({
  value,
  onChange,
  label,
  loading,
}) => {
  return (
    <FilterSelect multiple value={value} onChange={onChange} label={label || i18n.specificities} loading={loading}>
      {accommodationSpecificities.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={value.indexOf(name) > -1} />
          <SpecificityChip specificity={name} sx={{ marginLeft: 1 }} />
        </MenuItem>
      ))}
    </FilterSelect>
  );
};

export default AccommodationSpecificitiesSelect;

import {
  ComputeEmergencyOccupanciesOptions,
  DelayRule,
  DelayRuleType,
  delayRuleTypes,
  HealthCenter,
  RorOptions,
  RorTypes,
  rorTypes,
  ViaTrajectoireOptions,
} from '@ambuliz/sabri-core';
import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { FormContainer, FormRow, FormSection, InputIncrement } from 'common/components/Form';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import React, { useEffect, useMemo, useState } from 'react';

import { ToggleGroup } from 'common/components';
import {
  getHealthCenterConfig,
  hasConfigChanged,
  HealthCenterConfig,
  updateHealthCenterConfig,
} from './healthCenterConfig';

type HealthCenterConfigFormProps = {
  healthCenter: HealthCenter;
  healthCenterGroups?: string[];
  onSuccess: () => void;
};

const HealthCenterConfigForm: React.FC<HealthCenterConfigFormProps> = ({
  healthCenter,
  healthCenterGroups = [],
  onSuccess,
}) => {
  const initialConfig = useMemo(() => getHealthCenterConfig(healthCenter), [healthCenter]);
  const [config, setConfig] = useState(initialConfig);
  const [hasChanged, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [configError, setConfigError] = useState(false);

  // Each time the config changes, we compare it to the initial config
  useEffect(() => {
    setChanged(hasConfigChanged(config, initialConfig));
  }, [config, initialConfig]);

  const resetConfig = () => setConfig(initialConfig);

  const handleChange =
    <K extends keyof HealthCenterConfig>(name: K) =>
    (value: HealthCenterConfig[K]) => {
      setConfig({ ...config, [name]: value });
    };

  const handleChangeLongitude = (value: string) => {
    setConfig({ ...config, location: { latitude: config.location?.latitude || 0, longitude: parseFloat(value) } });
  };

  const handleChangeLatitude = (value: string) => {
    setConfig({ ...config, location: { longitude: config.location?.longitude || 0, latitude: parseFloat(value) } });
  };

  const handleRorChange =
    <K extends keyof RorOptions>(name: K) =>
    (value?: RorOptions[K]) => {
      setConfig({ ...config, rorOptions: { ...config.rorOptions, [name]: value } });
      setConfigError(false);
    };

  const handleViaTrajectoireChange =
    <K extends keyof ViaTrajectoireOptions>(name: K) =>
    (value?: ViaTrajectoireOptions[K]) => {
      setConfig({ ...config, viaTrajectoireOptions: { ...config.viaTrajectoireOptions, [name]: value } });
    };

  const toggleBookedBedDelayRule = (value: boolean) => {
    const bookedBedDelayRule = value ? initialConfig.bookedBedDelayRule || { type: 'TODAY_AT', value: 0 } : undefined;
    setConfig({ ...config, bookedBedDelayRule });
  };

  const handleBookBedDelayRuleChange =
    <K extends keyof DelayRule>(name: K) =>
    (value: DelayRule[K]) => {
      setConfig({
        ...config,
        bookedBedDelayRule: { ...(config.bookedBedDelayRule || ({} as DelayRule)), [name]: value },
      });
    };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    if (config.rorOptions?.isActivated && !(config.rorOptions?.username && config.rorOptions?.affiliatedRor)) {
      toast.error(i18n.messageErrorHealthCenterUpdate);
      setConfigError(true);
    } else if (config.viaTrajectoireOptions?.isActivated && !config.viaTrajectoireOptions.codeSih) {
      toast.error(i18n.messageErrorHealthCenterUpdate);
      setConfigError(true);
    } else {
      try {
        await updateHealthCenterConfig(healthCenter.id, config);
        onSuccess();
        toast.success(i18n.messageSuccessHealthCenterUpdate);
      } catch (error) {
        toast.error(i18n.messageErrorHealthCenterUpdate);
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: 8 }}>
      <FormContainer>
        <FormRow label={i18n.healthCenterName}>
          <TextField
            variant="outlined"
            value={config.name}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleChange('name')(evt.target.value)}
          />
        </FormRow>
        <FormRow label={'N° FINESS'}>
          <TextField
            variant="outlined"
            value={config.finess}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleChange('finess')(evt.target.value)}
          />
        </FormRow>
        <FormRow label={'Géolocalisation'}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Latitude"
              variant="outlined"
              value={config.location?.latitude}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleChangeLatitude(evt.target.value)}
            />
            <TextField
              label="Longitude"
              variant="outlined"
              value={config.location?.longitude}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleChangeLongitude(evt.target.value)}
            />
          </Stack>
        </FormRow>
        <FormSection label={i18n.healthCenterGroup}>
          <FormRow label={i18n.healthCenterGroupName}>
            <Autocomplete
              options={healthCenterGroups}
              value={config.group || null}
              freeSolo
              style={{ minWidth: 300 }}
              onInputChange={(_, value) => handleChange('group')(value || undefined)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={i18n.noHealthCenterGroupSelected} />
              )}
            />
          </FormRow>
        </FormSection>

        <FormSection
          label="Autodispatch"
          checked={config.autodispatchActivated}
          onChange={handleChange('autodispatchActivated')}
        >
          {config.autodispatchActivated && (
            <FormRow label={i18n.autodispatchTimeInMinutesAnticipation}>
              <InputIncrement
                value={config.autodispatchAnticipation}
                onChange={handleChange('autodispatchAnticipation')}
              />
            </FormRow>
          )}
        </FormSection>

        <FormSection label="Calculs auto">
          <FormRow label="Occupation des lits">
            <Switch
              checked={config.autoComputeOccupanciesActivated || false}
              onChange={(event) => handleChange('autoComputeOccupanciesActivated')(event.target.checked)}
            />
          </FormRow>

          <FormRow label="Occupation des urgences">
            <ToggleGroup
              value={!config.computeEmergencyOccupancies ? 'NONE' : config.computeEmergencyOccupancies}
              onChange={(_, value: ComputeEmergencyOccupanciesOptions | 'NONE') =>
                handleChange('computeEmergencyOccupancies')(value === 'NONE' ? undefined : value)
              }
            >
              <ToggleButton value={'NONE'}>Désactivé</ToggleButton>
              <ToggleButton value={'MANUAL' as ComputeEmergencyOccupanciesOptions}>Manuel</ToggleButton>
              <ToggleButton value={'AUTO' as ComputeEmergencyOccupanciesOptions}>Auto</ToggleButton>
            </ToggleGroup>
          </FormRow>

          <FormRow label="Séparer les urgences pédiatriques">
            <Switch
              checked={config.hasPediatricService || false}
              onChange={(event) => handleChange('hasPediatricService')(event.target.checked)}
            />
          </FormRow>
        </FormSection>

        <FormSection
          label="Groupement de missions"
          checked={config.fareGroupActivated}
          onChange={handleChange('fareGroupActivated')}
        >
          {config.fareGroupActivated && (
            <FormRow label={i18n.fareGroupTimeInterval}>
              <InputIncrement value={config.fareGroupTimeInterval} onChange={handleChange('fareGroupTimeInterval')} />
            </FormRow>
          )}
        </FormSection>

        <FormSection label="Sorties automatiques">
          <FormRow label="Activer la sortie automatique des patients avec une date de sortie confirmée dépassée">
            <Switch
              checked={config.autoComputeDischarge || false}
              onChange={(event) => handleChange('autoComputeDischarge')(event.target.checked)}
            />
          </FormRow>
        </FormSection>

        <FormSection
          label="Interconnexion au ROR"
          checked={config.rorOptions?.isActivated || false}
          onChange={handleRorChange('isActivated')}
        >
          {config.rorOptions?.isActivated && (
            <>
              <FormRow label={'ROR username'}>
                <TextField
                  error={configError && !config.rorOptions?.username}
                  helperText={configError && !config.rorOptions?.username && i18n.formErrors.required}
                  variant="outlined"
                  value={config.rorOptions?.username}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleRorChange('username')(evt.target.value)}
                />
              </FormRow>

              <FormRow label="ROR affilié">
                <Select
                  value={config.rorOptions?.affiliatedRor || ''}
                  error={configError && !config.rorOptions?.affiliatedRor}
                  variant="outlined"
                  label={'ROR'}
                  fullWidth
                  name="ROR"
                  onChange={(evt: SelectChangeEvent<string>) =>
                    handleRorChange('affiliatedRor')(evt.target.value as RorTypes)
                  }
                >
                  {rorTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormRow>

              <FormRow label={'ROR Email'}>
                <TextField
                  error={configError && !config.rorOptions?.receiverEmail}
                  helperText={configError && !config.rorOptions?.receiverEmail && i18n.formErrors.required}
                  variant="outlined"
                  value={config.rorOptions?.receiverEmail}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                    handleRorChange('receiverEmail')(evt.target.value)
                  }
                />
              </FormRow>
            </>
          )}
        </FormSection>

        <FormSection
          label="ViaTrajectoire"
          checked={config.viaTrajectoireOptions?.isActivated || false}
          onChange={(value) => handleViaTrajectoireChange('isActivated')(value)}
        >
          {config.viaTrajectoireOptions?.isActivated && (
            <FormRow label="Code SIH">
              <TextField
                error={!config.viaTrajectoireOptions.codeSih}
                helperText={
                  !config.viaTrajectoireOptions.codeSih && 'Code nécessaire au fonctionnement de ViaTrajectoire'
                }
                value={config.viaTrajectoireOptions.codeSih}
                onChange={(evt) => handleViaTrajectoireChange('codeSih')(evt.target.value)}
              />
            </FormRow>
          )}
        </FormSection>

        <FormSection
          label="Transport sanitaire"
          checked={config.patientTransportEnabled || false}
          onChange={handleChange('patientTransportEnabled')}
        />

        <FormSection
          label="Calcul des anomalies"
          checked={config.computeAnomaliesActivated || false}
          onChange={handleChange('computeAnomaliesActivated')}
        ></FormSection>

        <FormSection label={i18n.portersLimitByFarePosition}>
          <FormRow label={i18n.portersLimitLyingDown}>
            <InputIncrement value={config.portersLimitLyingDown} onChange={handleChange('portersLimitLyingDown')} />
          </FormRow>
          <FormRow label={i18n.portersLimitLyingDownBed}>
            <InputIncrement
              value={config.portersLimitLyingDownBed}
              onChange={handleChange('portersLimitLyingDownBed')}
            />
          </FormRow>
          <FormRow label={i18n.portersLimitSittingUp}>
            <InputIncrement value={config.portersLimitSittingUp} onChange={handleChange('portersLimitSittingUp')} />
          </FormRow>
          <FormRow label={i18n.portersLimitWalking}>
            <InputIncrement value={config.portersLimitWalking} onChange={handleChange('portersLimitWalking')} />
          </FormRow>
        </FormSection>

        <FormSection
          label={i18n.bookedBedConfig.title}
          checked={!!config.bookedBedDelayRule}
          onChange={toggleBookedBedDelayRule}
        >
          {!!config.bookedBedDelayRule && (
            <FormRow label={i18n.bookedBedConfig.delayRule}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Select
                  onChange={(e) => handleBookBedDelayRuleChange('type')(e.target.value as DelayRuleType)}
                  value={config.bookedBedDelayRule.type}
                >
                  {delayRuleTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {i18n.bookedBedConfig.delayType[type]}
                    </MenuItem>
                  ))}
                </Select>
                <InputIncrement
                  value={config.bookedBedDelayRule.value}
                  onChange={(value) => {
                    if (config.bookedBedDelayRule?.type === 'HOURS') {
                      handleBookBedDelayRuleChange('value')(value);
                    } else {
                      handleBookBedDelayRuleChange('value')(value <= 0 ? 0 : value >= 24 ? 24 : value);
                    }
                  }}
                />
                <Typography>{i18n.bookedBedConfig.hour}</Typography>
              </Stack>
            </FormRow>
          )}
        </FormSection>

        <FormSection>
          <Grid container spacing={2} paddingBottom={4} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" disabled={!hasChanged || loading} onClick={resetConfig}>
                {i18n.cancelEdition}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" disabled={!hasChanged || loading}>
                {i18n.save}
              </Button>
            </Grid>
          </Grid>
        </FormSection>
      </FormContainer>
    </form>
  );
};

export default HealthCenterConfigForm;

import {
  AccommodationSpecificity,
  AccommodationStatus,
  PatientGender,
  PerformerRequest,
  RequestStatus,
} from '@ambuliz/sabri-core';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { AddAccommodationDialog } from 'kurt/components';
import CancelAccommodationDialog from 'kurt/components/CancelAccommodation/CancelAccommodationDialog';
import {
  AcceptMutationRequest,
  CancelAccommodation,
  CancelBed,
  ConfirmAdmission,
  NewMutationRequest,
  RejectMutationRequest,
  UpdateBed,
  UpdateDate,
  UpdateDates,
  UpdateMutationDate,
  UpdateSpecificities,
  UpdateUnit,
} from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';
import AddressMutationRequest from 'kurt/components/PatientFlowKanban/VisitActions/actionForms/AddressMutationRequest';
import EditMutationPerformingRequest from 'kurt/components/PatientFlowKanban/VisitActions/actionForms/EditMutationPerformingRequest';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';
import AddAccommodationRequestForm from 'kurt/components/SendAccommodationRequest/SendAccommodationRequestForm';
import {
  AccommodationRequestToCreate,
  useAccommodation,
  useAccommodationRequest,
  usePerformerRequest,
} from 'kurt/hooks';
import { MakeBedAvailableDialog } from 'kurt/pages/Unit/Dialogs';
import CancelBedClosedDialog from 'kurt/pages/Unit/Dialogs/CancelBedClosedDialog';
import { showEmoji, toastEmojis } from './actionEmojis';

type MovementActionFormProps = {
  loading?: boolean;
  onCancel: () => void;
  onClose?: () => void;
  onSuccess?: () => void;
  open: boolean;
  action: AccommodationAction;
  id: string;
  requestId?: string;
  performerRequests?: PerformerRequest[];
  specificities?: AccommodationSpecificity[];
  bedId?: string;
  unitId?: string;
  previousAccommodationId?: string;
  nextAccommodationId?: string;
  admissionDate?: Date;
  dischargeDate?: Date;
  comment?: string;
  patient?: {
    id?: string;
    pan?: string;
    ipp?: string;
    name?: string;
    birthdate?: Date;
    gender?: PatientGender;
  };
};
export type ActionProps = {
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (
    action: AccommodationAction,
    accommodation?: {
      startAt?: Date | null;
      endAt?: Date | null;
      bedId?: string | null;
      unitId?: string | null;
      specificities?: AccommodationSpecificity[];
      isEndEstimated?: boolean;
      status?: AccommodationStatus;
    },
    request?: {
      startAt?: Date | null;
      reason?: string;
      status?: RequestStatus;
      performer?: string;
    },
    accommodationRequest?: AccommodationRequestToCreate
  ) => void;
};

type ActionFormProps = {
  id?: string;
  action: AccommodationAction;
  specificities?: AccommodationSpecificity[];
  bedId?: string;
  patient?: {
    name?: string;
    birthdate?: Date;
    gender?: PatientGender;
  };
  unitId?: string;
  admissionDate?: Date;
  dischargeDate?: Date;
  comment?: string;
  onClose?: () => void;
};

const ActionForm = ({
  action,
  onCancel,
  onSubmit,
  specificities = [],
  bedId,
  id,
  admissionDate,
  dischargeDate,
  loading,
  unitId,
  patient,
  comment,
  onClose,
}: ActionProps & ActionFormProps) => {
  switch (action) {
    case 'SET_BED':
    case 'UPDATE_BED':
    case 'SET_MUTATION_BED':
      if (unitId) {
        return (
          <UpdateBed
            onCancel={onCancel}
            initialUnitId={unitId}
            initialBedId={bedId}
            action={action}
            onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
            loading={loading}
            start={admissionDate}
            patient={patient}
            end={dischargeDate}
            specificities={specificities}
          />
        );
      }
      return null;
    case 'UPDATE_UNIT':
      return (
        <UpdateUnit
          onCancel={onCancel}
          action={action}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
        />
      );
    case 'UPDATE_SPECIFICITIES':
      return (
        <UpdateSpecificities
          initialSpecificities={specificities}
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
        />
      );
    case 'UPDATE_DATES':
      return (
        <UpdateDates
          admissionDate={admissionDate}
          dischargeDate={dischargeDate}
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
        />
      );
    case 'CANCEL_ADMISSION':
    case 'CANCEL_MUTATION_REQUEST':
    case 'CANCEL_DISCHARGE':
      return (
        <CancelAccommodation
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          action={action}
          loading={loading}
        />
      );
    case 'CONFIRM_ADMISSION':
      return (
        <ConfirmAdmission
          onCancel={onCancel}
          onSubmit={(accommodation) => onSubmit(action, accommodation)}
          loading={loading}
        />
      );
    case 'DISCHARGE_PATIENT':
      return (
        <UpdateDate
          action={action}
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          dischargeDate={new Date()}
          loading={loading}
        />
      );
    case 'VALIDATE_DISCHARGE_DATE':
    case 'UPDATE_PLANNED_DISCHARGE_DATE':
    case 'UPDATE_VALIDATED_DISCHARGE_DATE':
    case 'INVALIDATE_DISCHARGE_DATE':
      return (
        <UpdateDate
          action={action}
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          admissionDate={admissionDate}
          dischargeDate={dischargeDate}
          loading={loading}
        />
      );
    case 'CANCEL_BED':
    case 'CANCEL_MUTATION_BED':
      return (
        <CancelBed
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          action={action}
          loading={loading}
        />
      );
    case 'ADDRESS_MUTATION_REQUEST':
      return (
        <AddressMutationRequest
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
          currentUnitId={unitId}
        />
      );
    case 'EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST':
      return (
        <EditMutationPerformingRequest
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
          currentUnitId={unitId}
        />
      );
    case 'ACCEPT_INTERNAL_MUTATION_REQUEST':
    case 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
      return (
        <AcceptMutationRequest
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
          locale={action}
        />
      );
    case 'UPDATE_MUTATION_DATE':
      return (
        <UpdateMutationDate
          onCancel={onCancel}
          onSubmit={(accommodation) => onSubmit(action, accommodation)}
          loading={loading}
        />
      );
    case 'REJECT_INTERNAL_MUTATION_REQUEST':
    case 'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
      return (
        <RejectMutationRequest
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
        />
      );
    case 'NEW_INTERNAL_MUTATION_REQUEST':
      return (
        <NewMutationRequest
          onCancel={onCancel}
          onSubmit={(accommodation, request) => onSubmit(action, accommodation, request)}
          loading={loading}
        />
      );
    case 'REQUEST_MUTATION':
      return (
        <AddAccommodationRequestForm
          onCancel={onCancel}
          initialPatient={patient as PatientSearchResult}
          onSubmit={(accommodationRequest) => {
            onSubmit('REQUEST_MUTATION', undefined, undefined, accommodationRequest);
          }}
          unitId={unitId || ''}
          loading={loading}
          specificities={specificities}
          comment={comment}
        />
      );

    default:
      return null;
  }
};

const MovementActionForm: React.FC<MovementActionFormProps> = ({
  action,
  onCancel,
  onClose,
  onSuccess,
  id,
  requestId,
  performerRequests,
  specificities,
  dischargeDate,
  admissionDate,
  previousAccommodationId,
  nextAccommodationId,
  bedId,
  unitId,
  open,
  patient,
  comment,
}) => {
  const { update, cancel, loading } = useAccommodation(id);
  const {
    cancel: cancelRequest,
    create: createAccommodationRequest,
    loading: accommodationRequestLoading,
  } = useAccommodationRequest(requestId ? requestId : id);

  const { accept, reject, create, loading: requestLoading } = usePerformerRequest();
  const onSubmit = async (
    action: AccommodationAction,
    accommodation?: {
      startAt?: Date | null;
      endAt?: Date | null;
      bedId?: string | null;
      unitId?: string | null;
      specificities?: AccommodationSpecificity[];
      comment?: string;
      isEndEstimated?: boolean;
      status?: AccommodationStatus;
    },
    request?: {
      startAt?: Date | null;
      reason?: string;
      status?: RequestStatus;
      performer?: string;
    },
    accommodationRequest?: AccommodationRequestToCreate
  ) => {
    try {
      switch (action) {
        case 'CANCEL_ADMISSION':
          await cancel();
          break;
        case 'ACCEPT_INTERNAL_MUTATION_REQUEST':
          if (request) {
            const performerRequest = performerRequests?.find(
              (performerRequest) => performerRequest.status === 'REQUESTED' || performerRequest.status === 'ACCEPTED'
            );
            if (performerRequest && request?.startAt) {
              await accept(performerRequest.id, request.startAt);
            }
          }
          break;
        case 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
          if (request) {
            const performerRequest = performerRequests?.find(
              (performerRequest) => performerRequest.status === 'REQUESTED' || performerRequest.status === 'ACCEPTED'
            );

            if (performerRequest && request?.startAt) {
              await accept(performerRequest.id, request.startAt);
            }
          }
          break;
        case 'REJECT_INTERNAL_MUTATION_REQUEST':
          if (request) {
            const performerRequest = performerRequests?.find(
              (request) => request.status === 'REQUESTED' || request.status === 'ACCEPTED'
            );
            if (performerRequest) {
              await reject(performerRequest.id, request.reason);
            }
          }
          break;
        case 'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN':
          if (request) {
            const performerRequest = performerRequests?.find(
              (request) => request.status === 'REQUESTED' || request.status === 'ACCEPTED'
            );

            if (performerRequest) {
              await reject(performerRequest.id, request.reason);
            }
          }
          break;
        case 'ADDRESS_MUTATION_REQUEST':
          if (request?.performer) {
            await create(id, request.performer);
          }
          break;
        case 'EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST':
          if (request?.performer) {
            const performerRequest = performerRequests?.find(
              (request) => request.status === 'REQUESTED' || request.status === 'ACCEPTED'
            );

            if (performerRequest) {
              await reject(performerRequest.id, request.reason);
            }
            await create(id, request.performer);
          }
          break;
        case 'NEW_INTERNAL_MUTATION_REQUEST':
          if (request?.performer) await create(id, request.performer);
          break;
        case 'CANCEL_MUTATION_REQUEST':
          await cancelRequest();
          break;
        case 'SET_MUTATION_BED':
          if (accommodation && previousAccommodationId) {
            await update(accommodation);
            await update({ isEndEstimated: false }, previousAccommodationId);
          }
          break;
        case 'CANCEL_MUTATION_BED':
          if (accommodation && previousAccommodationId) {
            await update(accommodation);
            await update({ isEndEstimated: true }, previousAccommodationId);
          }
          break;
        case 'UPDATE_MUTATION_DATE':
          if (nextAccommodationId) {
            await update({ startAt: accommodation?.startAt }, nextAccommodationId);
          }
          break;
        case 'REQUEST_MUTATION':
          if (accommodationRequest) {
            await createAccommodationRequest(accommodationRequest);
          }
          break;

        default:
          if (accommodation) {
            await update(accommodation);
          }
          break;
      }
      toast.success({ ...i18n.visitActions[action].success, icon: toastEmojis[action] });
      (onSuccess || onClose || onCancel)();
    } catch (err) {
      toast.error(i18n.visitActions[action].error?.popupError || i18n.globalError);
    }
  };

  switch (action) {
    case 'CANCEL_ACCOMMODATION':
      return (
        <CancelAccommodationDialog
          open={open}
          id={id}
          onClose={onClose || onCancel}
          onSuccess={onSuccess || onCancel}
          onCancel={onCancel}
          onBack={onCancel}
        />
      );

    case 'CANCEL_CLOSED_BED':
      return (
        <CancelBedClosedDialog
          open={open}
          id={id}
          onClose={onClose || onCancel}
          onSuccess={onSuccess || onCancel}
          onCancel={onCancel}
          onBack={onCancel}
        />
      );

    case 'REOPEN_BED':
      return (
        <MakeBedAvailableDialog
          open={open}
          id={id}
          onClose={onClose || onCancel}
          onCancel={onCancel}
          onBack={onCancel}
          onSuccess={onSuccess || onCancel}
        />
      );
    case 'ADD_ACCOMMODATION':
      return (
        <AddAccommodationDialog
          open={open}
          onClose={onClose || onCancel}
          onBack={onCancel}
          onSuccess={onClose || onCancel}
          initialType="PATIENT_STAY"
          accommodation={{ bedId, unitId }}
        />
      );
    case 'CLOSE_BED':
      return (
        <AddAccommodationDialog
          open={open}
          onClose={onClose || onCancel}
          onBack={onCancel}
          onSuccess={onClose || onCancel}
          initialType="BED_CLOSURE"
          accommodation={{ bedId, unitId }}
        />
      );
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth={action === 'REQUEST_MUTATION' ? 'md' : 'sm'}
      fullWidth={action === 'REQUEST_MUTATION'}
      scroll="body"
    >
      <div style={{ minWidth: 518 }}>
        <DialogHeader
          onBack={onCancel}
          title={
            <Typography variant="h2">
              {showEmoji(action) && (
                <Emoji name={action === 'CANCEL_BED' ? 'sleepingFace' : 'thinkingFace'} size={24} inline />
              )}
              <span style={{ marginLeft: showEmoji(action) ? 8 : undefined }}>
                {i18n.visitActions[action].form.title}
              </span>
            </Typography>
          }
          onClose={onCancel}
        />
        <DialogContent>
          <ActionForm
            id={id}
            action={action}
            onCancel={onCancel}
            onClose={onClose}
            onSubmit={onSubmit}
            loading={loading || requestLoading || accommodationRequestLoading}
            specificities={specificities}
            dischargeDate={dischargeDate}
            admissionDate={admissionDate}
            bedId={bedId}
            unitId={unitId}
            patient={patient}
            comment={comment}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default MovementActionForm;

import { Accommodation } from '@ambuliz/sabri-core';
import { Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Comment, Path, UsersOutline } from 'common/components/Icons';
import DoctorIcon from 'common/components/Icons/DoctorIcon';
import { formatDateTime, formatName } from 'common/utils';
import { AccommodationSpecificityBadges } from 'kurt/components';
import AdmissionStatusChip from 'kurt/components/StepStatus/AdmissionStatusChip';
import PatientCard from 'kurt/pages/Movements/MovementSheets/Cards/PatientCard';
import BedName from 'kurt/pages/Unit/BedName';
import { getStatus } from '../hooks/getStatus';
import { UnitTableCell } from './UnitTableCell';

export type AccommodationResultRowProps = {
  accommodation: Accommodation;
};

export const AccommodationResultRow = ({ accommodation }: AccommodationResultRowProps) => {
  const { visit } = accommodation;

  if (!visit) {
    return;
  }

  const status = getStatus(accommodation);

  return (
    <TableRow key={accommodation.id}>
      <TableCell>
        <PatientCard
          name={formatName(visit.firstName, visit.lastName)}
          birthdate={visit.birthdate}
          gender={visit.gender}
        />
      </TableCell>
      <TableCell>
        <Typography>{visit.pan}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{visit.ipp}</Typography>
      </TableCell>
      <TableCell>
        <AccommodationSpecificityBadges specificities={accommodation.specificities} direction="row" />
      </TableCell>
      <TableCell>{accommodation.bed && <BedName name={accommodation.bed.name} />}</TableCell>
      <UnitTableCell unit={accommodation.unit} />
      <UnitTableCell unit={accommodation.responsibleUnit} />
      <TableCell>{status && <AdmissionStatusChip status={status} />}</TableCell>
      <TableCell>
        <Typography variant="body2" fontWeight={600} color="text.secondary">
          {accommodation.startAt ? formatDateTime(accommodation.startAt) : '-'}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={600}
          color={accommodation.isEstimatedEnd ? 'warning.main' : 'text.secondary'}
        >
          {accommodation.endAt ? formatDateTime(accommodation.endAt) : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" justifyContent="right" gap={1}>
          {accommodation.comment && (
            <Tooltip placement="top" title={accommodation.comment}>
              <Stack>
                <Comment />
              </Stack>
            </Tooltip>
          )}
          {!!accommodation.practitioners?.length && (
            <Tooltip placement="top" title={'Médecins: ' + accommodation.practitioners.map((p) => p.name).join(',')}>
              <Stack>
                <DoctorIcon />
              </Stack>
            </Tooltip>
          )}
          {accommodation.createdBy && (
            <Tooltip placement="top" title={'Initiateur: ' + accommodation.createdBy.username}>
              <Stack>
                <UsersOutline />
              </Stack>
            </Tooltip>
          )}
          {accommodation.externalIds && (
            <Tooltip placement="top" title={'ID externes: ' + accommodation.externalIds.join(',')}>
              <Stack>
                <Path />
              </Stack>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
